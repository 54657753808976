import React, { Fragment } from 'react';
import {ReactComponent as ReactEraser} from '../images/eraser.svg';

const RightClick = (props) => {

    const  handledelete = (e) => {
        props.Delete(e);
    }

    return (
        <Fragment>
            {
                props.contextMenu === true && (
                    <svg id="clickDelete" x={props.size === 2 ? parseFloat(props.x)+60 : parseFloat(props.x)+45 } y={props.size === 2 ? parseFloat(props.y)+60 : parseFloat(props.y)+45 } width="150" height="30" style={{"cursor" : "pointer"}} onPointerDown={handledelete}>
                        <rect fill="#7b7b7b" width="100%" height="100%" rx="10" ry="10"/>
                        <ReactEraser height="24" width="24" x="10" y="3"/>
                        <text textAnchor="middle" x="90" y="17" dominantBaseline="middle" fill="black" fontFamily="arial" fontSize="20" >Supprimer</text>
                    </svg>
                )
            }
        </Fragment>
    );

}

export default RightClick;