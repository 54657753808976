import React, { Fragment } from 'react';

const Aide = (props) => {

    const handleClick = (e) => {
        e.preventDefault();
        props.showHelp(e);
    }

    return ( 
        <Fragment>
        {
            props.help === true && (
                <svg width="550" height="600" x="-275" y="-350">
                    <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%" rx="4" ry="4"  stroke="black" strokeWidth="16"/>
                    <rect x="0" y="0" fill="#000000" width="100%" height="40" rx="4" ry="4"/>
                    <rect x="0" y="20" fill="#000000" width="100%" height="20"/>
                    <svg x="516" y="6" width="29" height="29" onPointerDown={handleClick} style={{"cursor" : "pointer"}}>
                        <rect x="0" y="0" rx="4" ry="4" width="29" height="29" fill="black" strokeWidth="8" stroke="#990000" />
                        <line x1="8" y1="8"
                          x2="21" y2="21" 
                          stroke="#990000" 
                          strokeWidth="4"/>
                        <line x1="21" y1="8"
                            x2="8" y2="21" 
                            stroke="#990000" 
                            strokeWidth="4"/>
                    </svg>
                    
                    
                    <text x="275" y="22" textAnchor="middle" dominantBaseline="middle" fill="#7b7b7b" fontFamily="arial" fontSize="20" fontWeight="bold" >Aide</text>
                    
                    <text  y="70"  dominantBaseline="middle" fill="black" fontFamily="arial" fontSize="20" fontWeight="bold">
                        <tspan x="12">Pour &nbsp;créer  une  &nbsp;formule  de  Lewis  vous  &nbsp;devez &nbsp;d'abord </tspan> 
                        <tspan x="12" dy="27">faire &nbsp;glisser &nbsp;les &nbsp;atomes &nbsp;que &nbsp;vous &nbsp;voulez &nbsp;depuis &nbsp;le </tspan>
                        <tspan x="12" dy="27">menu &nbsp;de &nbsp;gauche &nbsp;puis &nbsp;vous allez &nbsp;tracer les &nbsp;doublets </tspan>
                        <tspan x="12" dy="27">liants &nbsp;et non &nbsp;liants à &nbsp;l'aide du &nbsp;crayon ( il est possible </tspan>
                        <tspan x="12" dy="27">de tracer les doublets &nbsp;après avoir cliqué sur le &nbsp;crayon </tspan>
                        <tspan x="12" dy="27">du &nbsp;menu de &nbsp;droite, &nbsp;pour désactiver &nbsp;le crayon &nbsp;il vous </tspan>
                        <tspan x="12" dy="27">suffit &nbsp;de cliquer&nbsp; à nouveau &nbsp;sur celui-ci).</tspan>

                        <tspan x="12" dy="54">Si vous souhaitez effacer un élément (atome ou liaison) </tspan>
                        <tspan x="12" dy="27">il vous suffit de faire un clique droit (ou un double clic)</tspan>
                        <tspan x="12" dy="27">sur l'élément en question et de cliquer sur 'Supprimer'.</tspan>

                        <tspan x="12" dy="54">Vous &nbsp;&nbsp;pouvez &nbsp;&nbsp;déplacer &nbsp;les &nbsp;&nbsp;atomes &nbsp;&nbsp;simplement &nbsp;en </tspan>
                        <tspan x="12" dy="27">cliquant &nbsp;dessus et &nbsp;en les &nbsp;faisant &nbsp;glisser. &nbsp;Attention il</tspan>
                        <tspan x="12" dy="27">n'est &nbsp;&nbsp;pas &nbsp;possible &nbsp;de &nbsp;&nbsp;déplacer &nbsp;&nbsp;les &nbsp;atomes &nbsp;quand</tspan>
                        <tspan x="12" dy="27">le crayon est activé.</tspan>

                        <tspan x="12" dy="54">Pour verifier &nbsp;la &nbsp;structure &nbsp;de &nbsp;Lewis &nbsp;crée &nbsp;vous &nbsp;devez </tspan>
                        <tspan x="12" dy="27">cliquer &nbsp;sur &nbsp;le &nbsp;bouton &nbsp;"Vérifier la formule de Lewis".</tspan>
                    </text>

                </svg>
            )
        }
        </Fragment>
     );
}
 
export default Aide;