import React,{ Component } from 'react';
import uuid from 'react-uuid';
import MenuDroite from './components/MenuDroite';
import MenuGauche from './components/MenuGauche';
import RightClick from './components/RightClick';
import Info from './components/Info';
import Aide from './components/Aide';
import Valider from './components/Valider';
import Juste from './components/Juste';
import Error from './components/Error';

const saveSvgAsPng = require('save-svg-as-png');

const imageOptions = {
  scale: 1,
  encoderOptions: 1,
  backgroundColor: 'white',
  top : -384,
  left : -545,
  width : 1090,
  height : 768,
}

const atomesInit = [
    {
        nom : "carbone",
        symbole: "C",
        electrons: ["Nord","Est","Sud","Ouest"]
    },
    {
        nom : "carbone2",
        symbole: "C",
        electrons: ["EstHaut","EstBas","OuestBas","OuestHaut"]
    },
    {
        nom : "carbone3",
        symbole: "C",
        electrons: ["NordGauche","NordDroit","Est","Ouest"]
    },
    {
        nom : "carbone4",
        symbole: "C",
        electrons: ["Nord","Ouest","EstHaut","EstBas"]
    },
    {
        nom : "carbone5",
        symbole: "C",
        electrons: ["Nord","Est","OuestHaut","OuestBas"]
    },
    {
        nom : "carbone6",
        symbole: "C",
        electrons: ["Est","OuestBasBas","OuestHautHaut","OuestMilieu"]
    },
    {
        nom : "carbone7",
        symbole: "C",
        electrons: ["Ouest","EstBasBas","EstHautHaut","EstMilieu"]
    },
    {
        nom : "HSud",
        symbole: "H",
        electrons: ["Sud"]
    },
    {
        nom : "HNord",
        symbole: "H",
        electrons: ["Nord"]
    },
    {
        nom : "HEst",
        symbole: "H",
        electrons: ["Est"]
    },
    {
        nom : "HOuest",
        symbole: "H",
        electrons: ["Ouest"]
    },
    {
        nom : "NOuest",
        symbole: "N",
        electrons: ["Nord","Sud","Ouest","EstHaut","EstBas"]
    },
    {
        nom : "N3Ouest",
        symbole: "N",
        electrons: ["OuestHautHaut","OuestBasBas","OuestMilieu","EstHaut","EstBas"]
    },
    {
        nom : "N3Est",
        symbole: "N",
        electrons: ["EstHautHaut","EstBasBas","EstMilieu","OuestHaut","OuestBas"]
    },
    {
        nom : "NEst",
        symbole: "N",
        electrons: ["Nord","Sud","Est","OuestHaut","OuestBas"]
    },
    {
        nom : "NNord",
        symbole: "N",
        electrons: ["Nord","Est","Ouest","SudGauche","SudDroit"]
    },
    {
        nom : "NSud",
        symbole: "N",
        electrons: ["Sud","Est","Ouest","NordGauche","NordDroit"]
    },
    {
        nom : "Nplus",
        symbole: "Nplus",
        electrons: ["Sud","Est","Ouest","Nord"]
    },
    {
        nom : "O1",
        symbole: "O",
        electrons: ["NordDroit","NordGauche","SudDroit","SudGauche","Est","Ouest"]
    },
    {
        nom : "O2",
        symbole: "O",
        electrons: ["Nord","Sud","EstBas","OuestBas","EstHaut","OuestHaut"]
    },
    {
        nom : "O3",
        symbole: "O",
        electrons: ["NordGauche","NordDroit","EstBas","OuestBas","EstHaut","OuestHaut"]
    },
    {
        nom : "O4",
        symbole: "O",
        electrons: ["SudGauche","SudDroit","EstBas","OuestBas","EstHaut","OuestHaut"]
    },
    {
        nom : "Oplus",
        symbole: "Oplus",
        electrons: ["NordGauche","NordDroit","Est","Ouest","Sud"]
    },
    {
        nom : "Omoins1",
        symbole: "Omoins",
        electrons: ["NordGauche","NordDroit","EstHaut","EstBas","SudGauche","SudDroit","Ouest"]
    },
    {
        nom : "Omoins2",
        symbole: "Omoins",
        electrons: ["NordGauche","NordDroit","OuestHaut","OuestBas","SudGauche","SudDroit","Est"]
    },
    {
        nom : "Omoins3",
        symbole: "O2moins",
        electrons: ["NordGauche","NordDroit","OuestHaut","OuestBas","SudGauche","SudDroit","EstHaut","EstBas"]
    },
    {
        nom : "Chlore",
        symbole: "Cl",
        electrons: ["NordDroit","NordGauche","SudDroit","SudGauche","EstBas","EstHaut","Ouest"]
    },
    {
        nom : "Chlore2",
        symbole: "Cl",
        electrons: ["NordDroit","NordGauche","SudDroit","SudGauche","OuestBas","OuestHaut","Est"]
    },
    {
        nom : "Chlore3",
        symbole: "Clmoins",
        electrons: ["NordDroit","NordGauche","SudDroit","SudGauche","OuestBas","OuestHaut","EstHaut","EstBas"]
    },
    {
        nom : "S1",
        symbole: "S",
        electrons: ["NordDroit","NordGauche","SudDroit","SudGauche","Est","Ouest"]
    },
    {
        nom : "S1",
        symbole: "S",
        electrons: ["Nord","OuestHaut","OuestBas","Sud","EstHaut","EstBas"]
    },
];

const leschoix = [
    { choix : "Sud", cx: "60", cy: "100"},
    { choix : "Nord", cx: "60", cy: "20"},
    { choix : "Est", cx: "100", cy: "60"},
    { choix : "Ouest", cx: "20", cy: "60"},
    { choix : "NordDroit", cx: "74", cy: "20"},
    { choix : "NordGauche", cx: "46", cy: "20"},
    { choix : "SudDroit", cx: "74", cy: "100"},
    { choix : "SudGauche", cx: "46", cy: "100"},
    { choix : "EstHaut", cx: "100", cy: "46"},
    { choix : "EstBas", cx: "100", cy: "74"},
    { choix : "OuestHaut", cx: "20", cy: "46"},
    { choix : "OuestBas", cx: "20", cy: "74"},
    { choix : "OuestHautHaut", cx: "20", cy: "42"},
    { choix : "OuestBasBas", cx: "20", cy: "78"},
    { choix : "EstHautHaut", cx: "100", cy: "42"},
    { choix : "EstBasBas", cx: "100", cy: "78"},
    { choix : "EstMilieu", cx: "100", cy: "60"},
    { choix : "OuestMilieu", cx: "20", cy: "60"},
];

const lesinfosAtomes = {
    C : [4,0],
    O : [2,4],
    H : [1,0],
    N : [3,2],
    Cl : [1,6],
    S : [2,4],
    Nplus : [4,0],
    Oplus : [3,2],
    Omoins : [1,6],
    O2moins : [0,8],
    Clmoins : [0,8],
}

class App extends Component {

  constructor() {
      super();
      this.state = {
          graphe: null,
          y: 0,
          selected: null,
          elemAsupr: null,
          OffsetX: 0,
          OffsetY: 0,
          posX: 0,
          posY: 0,
          contextMenu: false,
          tracerLiaison : false,
          entraindetracerLiaison: false,
          dictLiaison: [],
          listeAtomes: [],
          liaisonsSelected : [],
          info : false,
          help : false,
          lesidvisite : [],
          premierDeplacement : false,
          estjuste : false,
          error1 : false,
          error2 : false,
          size : 3,
          LiaisonBegin: false,
          visite : [],
          nombremolecule : 0,
      }

      atomesInit.forEach( (atome,index) => {
          atome.id = uuid();
          let lesids = [];
          for(let i=0;i<atome.electrons.length;i++){
              let xy = leschoix.find(e => e.choix === atome.electrons[i])
              let id = atome.id + atome.electrons[i] + String(index);
              lesids.push([id,atome.electrons[i],parseFloat(xy.cx),parseFloat(xy.cy)])
          }
          atome.ids = lesids;
          atome._x = -10;
          atome._y = 60*index+15; // + y que l'on ajoute après
      });

      this.startDragElement = this.startDragElement.bind(this);
      this.DragElement = this.DragElement.bind(this);
      this.stopDragElement = this.stopDragElement.bind(this);

      this.changerY = this.changerY.bind(this);
      this.electronOut = this.electronOut.bind(this);
      this.electronOver = this.electronOver.bind(this);
      this.DeleteAll = this.DeleteAll.bind(this);
      this.updateStageAtomes = this.updateStageAtomes.bind(this);

      this.Delete = this.Delete.bind(this);
      this.showInfo = this.showInfo.bind(this);
      this.showHelp = this.showHelp.bind(this);
      this.showJuste = this.showJuste.bind(this);
      this.showError = this.showError.bind(this);
      this.changeAllo = this.changeAllo.bind(this);
      this.zoomPlus = this.zoomPlus.bind(this);
      this.zoomMoins = this.zoomMoins.bind(this);
      this.Delete = this.Delete.bind(this);
      this.validate = this.validate.bind(this);
  }

  async componentDidUpdate(prevProps, prevState){
    if( prevState.dictLiaison !== this.state.dictLiaison ){
        localStorage.setItem('dictLiaison', JSON.stringify(this.state.dictLiaison) );
    }
    if( prevState.listeAtomes !== this.state.listeAtomes ){
        localStorage.setItem('listeAtomes', JSON.stringify(this.state.listeAtomes) );
    }
    if( prevState.size !== this.state.size ){
        localStorage.setItem('size', this.state.size );
    }
    if( prevState.nombremolecule !== this.state.nombremolecule ){
        localStorage.setItem('nombremolecule', this.state.nombremolecule );
    }
  }
 
  async updateGraphe(){

    await this.setState({
        graphe : null,
    })

    let liaisons = [...this.state.dictLiaison];

    let atomeParcourus = [];

    let graphe = new Map();

    for(let i=0; i<liaisons.length;i++){

        if( typeof(graphe.get(liaisons[i].IdAtomeStart)) === "undefined" ){
            graphe.set(liaisons[i].IdAtomeStart,[liaisons[i].IdAtomeEnd]);
            atomeParcourus.push(liaisons[i].IdAtomeStart);
        }else{
            let lesvaleurs = graphe.get(liaisons[i].IdAtomeStart);
            graphe.set(liaisons[i].IdAtomeStart,[...lesvaleurs, liaisons[i].IdAtomeEnd])
        }

        if( typeof(graphe.get(liaisons[i].IdAtomeEnd)) === "undefined" ){
            graphe.set(liaisons[i].IdAtomeEnd,[liaisons[i].IdAtomeStart]);
            atomeParcourus.push(liaisons[i].IdAtomeEnd);
        }else{
            let lesvaleurs = graphe.get(liaisons[i].IdAtomeEnd);
            graphe.set(liaisons[i].IdAtomeEnd,[...lesvaleurs, liaisons[i].IdAtomeStart])
        }

    }

    if(atomeParcourus.length < this.state.listeAtomes.length){
        let la = this.state.listeAtomes.filter(e => atomeParcourus.includes(e.id) === false);

        for(let i=0; i<la.length;i++){
            graphe.set(la[i].id,[]);
        }
    }

    await this.setState({
        graphe : graphe,
    })

  }


  async parcours_profondeur(graphe,noeud,visite){
    
    visite.push(noeud);

    if(graphe.has(noeud) === true){
        for(const voisin of graphe.get(noeud)){
            //console.log(`Un voisin de ${noeud} est ${voisin}`)
            if(visite.indexOf(voisin) === -1){
                //console.log(`Je suis sur ${noeud} et je vais visiter ${voisin}`);
                this.setState({
                    visite : visite,
                })
                await this.realignerUneLiaison(noeud, voisin);
                await this.parcours_profondeur(graphe,voisin,visite)
            }else if(noeud === voisin){
                await this.realignerUneLiaison(noeud, voisin);
                //console.log(`je suis sur ${noeud} et je réaligne les doublets non liants`);
            }else{
                //console.log(`je suis sur ${noeud} et j'ai deja visite ${voisin}`);
            }
        }
    }else{
        console.log(`${noeud} n'est pas dans le graphe`);
    }
  }

  async realignerUneLiaison(noeud, voisin){

    let lesliaisons = [...this.state.dictLiaison];

    // on met à jour la position des atomes

    let elem1 = null;
    let elem2 = null;

    elem1 = lesliaisons.filter(elem => ( elem.IdAtomeStart === noeud && elem.IdAtomeEnd === voisin ));

    elem2 = lesliaisons.filter(elem => ( elem.IdAtomeStart === voisin && elem.IdAtomeEnd === noeud ));

    let touslesatomes = [...this.state.listeAtomes];

    let latomeNoeud = this.state.listeAtomes.filter(e => e.id === noeud)[0];

    let latomeVoisin = this.state.listeAtomes.filter(e => e.id === voisin)[0];

    let pXi = latomeNoeud.x;
    let pYi = latomeNoeud.y; 

    let orientation_i = null;
    let orientation_f = null;

    let pXfAtome = null;
    let pYfAtome = null;

    if(elem1.length > 0){
        orientation_i = elem1[0].orientationStart;
        orientation_f = elem1[0].orientationEnd;
    }else if(elem2.length > 0){
        orientation_i = elem2[0].orientationEnd;
        orientation_f = elem2[0].orientationStart;
    }

    let piElectron = latomeNoeud.ids.find(e => e[1] === orientation_i );
    let pfElectron = latomeVoisin.ids.find(e => e[1] === orientation_f );

    if(noeud === voisin){
        // on ne fait rien
    }else{
        if( (orientation_i === "Est" && orientation_f === "Ouest") || (orientation_i === "EstMilieu" && orientation_f === "OuestMilieu") || (orientation_i === "EstHaut" && orientation_f === "OuestHaut") || (orientation_i === "EstBas" && orientation_f === "OuestBas")  || (orientation_i === "EstHautHaut" && orientation_f === "OuestHautHaut") || (orientation_i === "EstBasBas" && orientation_f === "OuestBasBas") ){

            switch(this.state.size){
                case 3 :
                    pXfAtome = pXi+(piElectron[2]*1.25)+75-(pfElectron[2]*1.25);
                    break;
                case 2 :
                    pXfAtome = pXi+piElectron[2]+60-pfElectron[2];
                    break;
                case 1 :
                    pXfAtome = pXi+(piElectron[2]*3/4)+45-(pfElectron[2]*3/4);
                    break;
            }
            pYfAtome = pYi;
         
        }else if( (orientation_i === "Ouest" && orientation_f === "Est") || (orientation_i === "OuestMilieu" && orientation_f === "EstMilieu") || (orientation_i === "OuestHaut" && orientation_f === "EstHaut") || (orientation_i === "OuestBas" && orientation_f === "EstBas")  || (orientation_i === "OuestHautHaut" && orientation_f === "EstHautHaut") || (orientation_i === "OuestBasBas" && orientation_f === "EstBasBas") ){

            switch(this.state.size){
                case 3 :
                    pXfAtome = pXi+(piElectron[2]*1.25)-75-(pfElectron[2]*1.25);
                    break;
                case 2 :
                    pXfAtome = pXi+piElectron[2]-60-pfElectron[2];
                    break;
                case 1 :
                    pXfAtome = pXi+(piElectron[2]*3/4)-45-(pfElectron[2]*3/4);
                    break;
            }

            pYfAtome = pYi;
 
        }else if( (orientation_i === "Sud" && orientation_f === "Nord") || (orientation_i === "SudGauche" && orientation_f === "NordGauche") || (orientation_i === "SudDroit" && orientation_f === "NordDroit") ){

            pXfAtome = pXi;
            
            switch(this.state.size){
                case 3 :
                    pYfAtome = pYi+(piElectron[3]*1.25)+75-(pfElectron[3]*1.25);
                    break;
                case 2 :
                    pYfAtome = pYi+piElectron[3]+60-pfElectron[3];
                    break;
                case 1 :
                    pYfAtome = pYi+(piElectron[3]*3/4)+45-(pfElectron[3]*3/4);
                    break;
            }

        }else if( (orientation_i === "Nord" && orientation_f === "Sud") || (orientation_i === "NordDroit" && orientation_f === "SudDroit") || (orientation_i === "NordGauche" && orientation_f === "SudGauche") ){

            pXfAtome = pXi;
            
            switch(this.state.size){
                case 3 :
                    pYfAtome = pYi+(piElectron[3]*1.25)-75-(pfElectron[3]*1.25);
                    break;
                case 2 :
                    pYfAtome = pYi+piElectron[3]-60-pfElectron[3];
                    break;
                case 1 :
                    pYfAtome = pYi+(piElectron[3]*3/4)-45-(pfElectron[3]*3/4);
                    break;
            }

        }else if( (orientation_i === "Est" && orientation_f === "Nord") || (orientation_i === "EstBas" && orientation_f === "Nord") ){

            switch(this.state.size){
                case 3 :
                    pXfAtome = pXi+(piElectron[2]*1.25)+37.5-(pfElectron[2]*1.25);
                    pYfAtome = pYi+(piElectron[3]*1.25)+37.5-(pfElectron[3]*1.25);
                    break;
                case 2 :
                    pXfAtome = pXi+piElectron[2]+30-pfElectron[2];
                    pYfAtome = pYi+piElectron[3]+30-pfElectron[3];
                    break;
                case 1 :
                    pXfAtome = pXi+(piElectron[2]*3/4)+22.5-(pfElectron[2]*3/4);
                    pYfAtome = pYi+(piElectron[3]*3/4)+22.5-(pfElectron[3]*3/4);
                    break;
            }

        }else if( (orientation_i === "Nord" && orientation_f === "Est") || (orientation_i === "Nord" && orientation_f === "EstBas") ){

            switch(this.state.size){
                case 3 :
                pXfAtome = pXi+(piElectron[2]*1.25)-37.5-(pfElectron[2]*1.25);
                pYfAtome = pYi+(piElectron[3]*1.25)-37.5-(pfElectron[3]*1.25);
                break;
                case 2 :
                pXfAtome = pXi+piElectron[2]-30-pfElectron[2];
                pYfAtome = pYi+piElectron[3]-30-pfElectron[3];
                break;
                case 1 :
                pXfAtome = pXi+(piElectron[2]*3/4)-22.5-(pfElectron[2]*3/4);
                pYfAtome = pYi+(piElectron[3]*3/4)-22.5-(pfElectron[3]*3/4);
                break;
            }

        }else if( (orientation_i === "Ouest" && orientation_f === "Nord") || (orientation_i === "OuestBas" && orientation_f === "Nord") ){

            switch(this.state.size){
                case 3 :
                pXfAtome = pXi+(piElectron[2]*1.25)-37.5-(pfElectron[2]*1.25);
                pYfAtome = pYi+(piElectron[3]*1.25)+37.5-(pfElectron[3]*1.25);
                break;
                case 2 :
                pXfAtome = pXi+piElectron[2]-30-pfElectron[2];
                pYfAtome = pYi+piElectron[3]+30-pfElectron[3];
                break;
                case 1 :
                pXfAtome = pXi+(piElectron[2]*3/4)-22.5-(pfElectron[2]*3/4);
                pYfAtome = pYi+(piElectron[3]*3/4)+22.5-(pfElectron[3]*3/4);
                break;
            }

        }else if( (orientation_i === "Nord" && orientation_f === "Ouest") || (orientation_i === "Nord" && orientation_f === "OuestBas") ){

            switch(this.state.size){
                case 3 :
                pXfAtome = pXi+(piElectron[2]*1.25)+37.5-(pfElectron[2]*1.25);
                pYfAtome = pYi+(piElectron[3]*1.25)-37.5-(pfElectron[3]*1.25);
                break;
                case 2 :
                pXfAtome = pXi+piElectron[2]+30-pfElectron[2];
                pYfAtome = pYi+piElectron[3]-30-pfElectron[3];
                break;
                case 1 :
                pXfAtome = pXi+(piElectron[2]*3/4)+22.5-(pfElectron[2]*3/4);
                pYfAtome = pYi+(piElectron[3]*3/4)-22.5-(pfElectron[3]*3/4);
                break;
            }
            
        }else if( (orientation_i === "Sud" && orientation_f === "Ouest") || (orientation_i === "Sud" && orientation_f === "OuestHaut") ){

            switch(this.state.size){
                case 3 :
                pXfAtome = pXi+(piElectron[2]*1.25)+37.5-(pfElectron[2]*1.25);
                pYfAtome = pYi+(piElectron[3]*1.25)+37.5-(pfElectron[3]*1.25);
                break;
                case 2 :
                pXfAtome = pXi+piElectron[2]+30-pfElectron[2];
                pYfAtome = pYi+piElectron[3]+30-pfElectron[3];
                break;
                case 1 :
                pXfAtome = pXi+(piElectron[2]*3/4)+22.5-(pfElectron[2]*3/4);
                pYfAtome = pYi+(piElectron[3]*3/4)+22.5-(pfElectron[3]*3/4);
                break;
            }

        }else if( (orientation_i === "Ouest" && orientation_f === "Sud") || (orientation_i === "OuestHaut" && orientation_f === "Sud") ){

            switch(this.state.size){
                case 3 :
                pXfAtome = pXi+(piElectron[2]*1.25)-37.5-(pfElectron[2]*1.25);
                pYfAtome = pYi+(piElectron[3]*1.25)-37.5-(pfElectron[3]*1.25);
                break;
                case 2 :
                pXfAtome = pXi+piElectron[2]-30-pfElectron[2];
                pYfAtome = pYi+piElectron[3]-30-pfElectron[3];
                break;
                case 1 :
                pXfAtome = pXi+(piElectron[2]*3/4)-22.5-(pfElectron[2]*3/4);
                pYfAtome = pYi+(piElectron[3]*3/4)-22.5-(pfElectron[3]*3/4);
                break;
            }

        }else if( (orientation_i === "Est" && orientation_f === "Sud") || (orientation_i === "EstHaut" && orientation_f === "Sud") ){

            switch(this.state.size){
                case 3 :
                pXfAtome = pXi+(piElectron[2]*1.25)+37.5-(pfElectron[2]*1.25);
                pYfAtome = pYi+(piElectron[3]*1.25)-37.5-(pfElectron[3]*1.25);
                break;
                case 2 :
                pXfAtome = pXi+piElectron[2]+30-pfElectron[2];
                pYfAtome = pYi+piElectron[3]-30-pfElectron[3];
                break;
                case 1 :
                pXfAtome = pXi+(piElectron[2]*3/4)+22.5-(pfElectron[2]*3/4);
                pYfAtome = pYi+(piElectron[3]*3/4)-22.5-(pfElectron[3]*3/4);
                break;
            }

        }else if( (orientation_i === "Sud" && orientation_f === "Est") || (orientation_i === "Sud" && orientation_f === "EstHaut") ){

            switch(this.state.size){
                case 3 :
                pXfAtome = pXi+(piElectron[2]*1.25)-37.5-(pfElectron[2]*1.25);
                pYfAtome = pYi+(piElectron[3]*1.25)+37.5-(pfElectron[3]*1.25);
                break;
                case 2 :
                pXfAtome = pXi+piElectron[2]-30-pfElectron[2];
                pYfAtome = pYi+piElectron[3]+30-pfElectron[3];
                break;
                case 1 :
                pXfAtome = pXi+(piElectron[2]*3/4)-22.5-(pfElectron[2]*3/4);
                pYfAtome = pYi+(piElectron[3]*3/4)+22.5-(pfElectron[3]*3/4);
                break;
            }

        }

        touslesatomes.forEach(elem => {
            if(elem.id === voisin){
                elem.x = pXfAtome;
                elem.y = pYfAtome;
            }
        })
    
        await this.setState({
            listeAtomes: touslesatomes,
        })

        let element1 = document.getElementById(voisin);
        element1.setAttributeNS(null,"x",pXfAtome);
        element1.setAttributeNS(null,"y",pYfAtome);

    }

    // on met à jour les liaisons

    lesliaisons.forEach( li => {

        // on s'occupe de l'atome start

        let posAtomeStart = this.state.listeAtomes.find(e => e.id === li.IdAtomeStart);
        let posElectronStart = leschoix.find(e => e.choix === li.orientationStart);

        switch(this.state.size){
            case 2:
            li.xStart = parseFloat(posAtomeStart.x) + parseFloat(posElectronStart.cx);
            li.yStart = parseFloat(posAtomeStart.y) + parseFloat(posElectronStart.cy);
            break;
            case 1 :
            li.xStart = parseFloat(posAtomeStart.x) + parseFloat(posElectronStart.cx*3/4);
            li.yStart = parseFloat(posAtomeStart.y) + parseFloat(posElectronStart.cy*3/4);
            break;
            case 3 :
            li.xStart = parseFloat(posAtomeStart.x) + parseFloat(posElectronStart.cx*1.25);
            li.yStart = parseFloat(posAtomeStart.y) + parseFloat(posElectronStart.cy*1.25);
            break;
        }

        // on s'occupe de l'atome end
  
        let posAtomeEnd = this.state.listeAtomes.find(e => e.id === li.IdAtomeEnd);
        let posElectronEnd = leschoix.find(e => e.choix === li.orientationEnd);

        switch(this.state.size){
            case 2:
            li.xEnd = parseFloat(posAtomeEnd.x) + parseFloat(posElectronEnd.cx);
            li.yEnd = parseFloat(posAtomeEnd.y) + parseFloat(posElectronEnd.cy);
            break;
            case 1 :
            li.xEnd = parseFloat(posAtomeEnd.x) + parseFloat(posElectronEnd.cx*3/4);
            li.yEnd = parseFloat(posAtomeEnd.y) + parseFloat(posElectronEnd.cy*3/4);
            break;
            case 3 :
            li.xEnd = parseFloat(posAtomeEnd.x) + parseFloat(posElectronEnd.cx*1.25);
            li.yEnd = parseFloat(posAtomeEnd.y) + parseFloat(posElectronEnd.cy*1.25);
            break;
        }

        let laliaison = document.getElementById(li.IdLiaison);
        laliaison.setAttribute("x1",li.xStart);
        laliaison.setAttribute("y1", li.yStart);

        laliaison.setAttribute("x2",li.xEnd);
        laliaison.setAttribute("y2",li.yEnd);

    });

    await this.setState({
        dictLiaison : lesliaisons,
    })

  }
  

  getXYOrientation(orientation){
    // a partir de l'orientation de l'electron on obtient ses coordonnés x et y
        let value = null;

        const leschoix = [
            { choix : "Sud", cx: "60", cy: "100"},
            { choix : "Nord", cx: "60", cy: "20"},
            { choix : "Est", cx: "100", cy: "60"},
            { choix : "Ouest", cx: "20", cy: "60"},
            { choix : "NordDroit", cx: "74", cy: "20"},
            { choix : "NordGauche", cx: "46", cy: "20"},
            { choix : "SudDroit", cx: "74", cy: "100"},
            { choix : "SudGauche", cx: "46", cy: "100"},
            { choix : "EstHaut", cx: "100", cy: "46"},
            { choix : "EstBas", cx: "100", cy: "74"},
            { choix : "OuestHaut", cx: "20", cy: "46"},
            { choix : "OuestBas", cx: "20", cy: "74"},
            { choix : "OuestHautHaut", cx: "20", cy: "42"},
            { choix : "OuestBasBas", cx: "20", cy: "78"},
            { choix : "EstHautHaut", cx: "100", cy: "42"},
            { choix : "EstBasBas", cx: "100", cy: "78"},
            { choix : "EstMilieu", cx: "100", cy: "60"},
            { choix : "OuestMilieu", cx: "20", cy: "60"},
        ];

        value = leschoix.find(e => e.choix === orientation);

        if(this.state.size === 2){
            return [value.cx, value.cy];
        }else if(this.state.size === 1){
            return [value.cx*3/4, value.cy*3/4];
        }else if(this.state.size === 3){
            return [value.cx*1.25, value.cy*1.25];
        }
  }

  async componentDidMount(){

    const boundingRect = await this.canvas.getBoundingClientRect();

    await this.setState({
        OffsetX: boundingRect.left,
        OffsetY: boundingRect.top,
        dictLiaison : JSON.parse(localStorage.getItem('dictLiaison')) || [],
        listeAtomes : JSON.parse(localStorage.getItem('listeAtomes')) || [],
        size : parseInt(localStorage.getItem('size')) || 2,
        nombremolecule : parseInt(localStorage.getItem('nombremolecule')) || 0,
    });  

    this.canvas.addEventListener("mousedown",this.startDragElement,false);
    this.canvas.addEventListener("mousemove",this.DragElement,false);
    this.canvas.addEventListener("mouseup",this.stopDragElement,false);

    this.canvas.addEventListener("touchstart",this.startDragElement,false);
    this.canvas.addEventListener("touchmove",this.DragElement,false);
    this.canvas.addEventListener("touchend",this.stopDragElement,false);
    this.canvas.addEventListener("touchcancel",this.stopDragElement,false);

    this.updateStageAtomes(true);

  }

  updateStageAtomes(start){

    if(start !== true){
        this.state.listeAtomes.forEach(e => {
            this.screen.removeChild(document.getElementById(e.id));
        });

        this.state.dictLiaison.forEach(e => {
            this.derriere.removeChild(document.getElementById(e.IdLiaison));
        });
    }

    var xmlns = "http://www.w3.org/2000/svg";

    this.state.listeAtomes.forEach(e => {

        var svg = document.createElementNS(xmlns, "svg");
        svg.setAttribute("viewBox","0 0 120 120");
        svg.setAttribute("id", e.id);

        switch(this.state.size){
            case 3:
                svg.setAttribute("width","150");
                svg.setAttribute("height","150");
                break;
            case 2:
                svg.setAttribute("width","120");
                svg.setAttribute("height","120");
                break;
            case 1:
                svg.setAttribute("width","90");
                svg.setAttribute("height","90");
                break;
        }

        svg.setAttribute("nom", e.nom);
        svg.setAttribute("symbole", e.symbole);
        svg.setAttribute("electronsids", JSON.stringify(e.ids));
        svg.setAttribute("electrons", JSON.stringify(e.electrons));
        svg.setAttribute("info", JSON.stringify(e));
        svg.setAttribute("x", e.x);
        svg.setAttribute("y", e.y);
        svg.setAttribute("where", "stage");
        svg.setAttribute("class", "atomeStage")
        svg.setAttribute("style", `cursor : ${this.state.tracerLiaison === false ? "pointer" : "default"}`);

        if(e.symbole.includes("plus") === true || e.symbole.includes("moins") === true){

            var path = document.createElementNS(xmlns, "text");
    
            path.setAttributeNS(null, 'x', '60');
            path.setAttributeNS(null, 'y', '65');
            path.setAttributeNS(null, 'text-anchor', 'middle');
            path.setAttributeNS(null, 'dominant-baseline', 'middle');
            path.setAttributeNS(null, 'fill', 'black');
            path.setAttributeNS(null, 'font-family', 'arial');
            
            switch(this.state.size){
                case 3:
                    path.setAttributeNS(null, 'font-size',"62");
                    break;
                case 2:
                    path.setAttributeNS(null, 'font-size',"60");
                    break;
                case 1:
                    path.setAttributeNS(null, 'font-size',"55");
                    break;
            }
    
            path.innerHTML = e.symbole.replace("plus","").replace("2moins","").replace("moins","");

            var path2 = document.createElementNS(xmlns, "text");
    
            path2.setAttributeNS(null, 'x', e.symbole.includes("2moins") === true ? "105" : '100');
            path2.setAttributeNS(null, 'y', e.symbole.includes("plus") === true ? "30" : "20");
            path2.setAttributeNS(null, 'text-anchor', 'middle');
            path2.setAttributeNS(null, 'dominant-baseline', 'middle');
            path2.setAttributeNS(null, 'fill', 'black');
            path2.setAttributeNS(null, 'font-family', 'arial');

            switch(this.state.size){
                case 3:
                    path2.setAttributeNS(null, 'font-size',"40");
                    break;
                case 2:
                    path2.setAttributeNS(null, 'font-size',"36");
                    break;
                case 1:
                    path2.setAttributeNS(null, 'font-size',"30");
                    break;
            }
    
            path2.innerHTML = e.symbole.includes("plus") === true ? "+" : e.symbole.includes("2moins") === true ? "2-" : e.symbole.includes("moins") === true && "-";
                        
            svg.appendChild(path);
            svg.appendChild(path2);   

        }else{

            var path = document.createElementNS(xmlns, "text");
    
            path.setAttributeNS(null, 'x', '60');
            path.setAttributeNS(null, 'y', '66');
            path.setAttributeNS(null, 'text-anchor', 'middle');
            path.setAttributeNS(null, 'dominant-baseline', 'middle');
            path.setAttributeNS(null, 'fill', 'black');
            path.setAttributeNS(null, 'font-family', 'arial');

            switch(this.state.size){
                case 3:
                    path.setAttributeNS(null, 'font-size',"62");
                    break;
                case 2:
                    path.setAttributeNS(null, 'font-size',"60");
                    break;
                case 1:
                    path.setAttributeNS(null, 'font-size',"55");
                    break;
            }
    
            path.innerHTML = e.symbole;

            svg.appendChild(path);

        }

        e.ids.forEach(idxy => {
            if(["EstMilieu","OuestMilieu","EstHautHaut","EstBasBas","OuestHautHaut","OuestBasBas"].includes(idxy[1])){

                var group = document.createElementNS(xmlns,"g");
                group.setAttribute("name","leselectrons");

                var circle = document.createElementNS(xmlns, "circle");
    
                circle.setAttributeNS(null, 'cx', idxy[2]);
                circle.setAttributeNS(null, 'cy', idxy[3]);
                circle.setAttributeNS(null, 'fill', 'black');
                circle.setAttributeNS(null, 'r', '4');
                circle.setAttributeNS(null, 'id_atome', e.id);
                circle.setAttributeNS(null, 'id_electron', idxy[0]);
                circle.setAttributeNS(null, 'orientation', idxy[1]);

                var circle2 = document.createElementNS(xmlns, "circle");
    
                circle2.setAttributeNS(null, 'cx', idxy[2]);
                circle2.setAttributeNS(null, 'cy', idxy[3]);
                circle2.setAttributeNS(null, 'fill', this.state.tracerLiaison === true ? "transparent" : "black");
                circle2.setAttributeNS(null, 'r', this.state.tracerLiaison === true ? "8" : "3");
                circle2.setAttributeNS(null, 'stroke-dasharray', '1 2');
                circle2.setAttributeNS(null, 'stroke', 'black');
                circle2.setAttributeNS(null, 'stroke-width', '1');
                circle2.setAttributeNS(null, 'id_atome', e.id);
                circle2.setAttributeNS(null, 'id_electron', idxy[0]);
                circle2.setAttributeNS(null, 'orientation', idxy[1]);
                circle2.setAttributeNS(null, 'id', idxy[0]);
                circle2.setAttributeNS(null, 'class', "allo");
                circle2.setAttribute("style", `cursor : ${this.state.tracerLiaison === false ? "default" : "crosshair"}`);

                circle2.addEventListener("pointerover",this.electronOver,false);
                circle2.addEventListener("pointerout",this.electronOut,false);

                group.appendChild(circle);
                group.appendChild(circle2);
                svg.appendChild(group);

            }else{

                var group = document.createElementNS(xmlns,"g");
                group.setAttribute("name","leselectrons");

                var circle = document.createElementNS(xmlns, "circle");
    
                circle.setAttributeNS(null, 'cx', idxy[2]);
                circle.setAttributeNS(null, 'cy', idxy[3]);
                circle.setAttributeNS(null, 'fill', 'black');
                circle.setAttributeNS(null, 'r', '7');
                circle.setAttributeNS(null, 'id_atome', e.id);
                circle.setAttributeNS(null, 'id_electron', idxy[0]);
                circle.setAttributeNS(null, 'orientation', idxy[1]);

                var circle2 = document.createElementNS(xmlns, "circle");
    
                circle2.setAttributeNS(null, 'cx', idxy[2]);
                circle2.setAttributeNS(null, 'cy', idxy[3]);
                circle2.setAttributeNS(null, 'fill', this.state.tracerLiaison === true ? "transparent" : "black");
                circle2.setAttributeNS(null, 'r', this.state.tracerLiaison === true ? "13" : "3");
                circle2.setAttributeNS(null, 'stroke-dasharray', '2 3');
                circle2.setAttributeNS(null, 'stroke', 'black');
                circle2.setAttributeNS(null, 'stroke-width', '2');
                circle2.setAttributeNS(null, 'id_atome', e.id);
                circle2.setAttributeNS(null, 'id_electron', idxy[0]);
                circle2.setAttributeNS(null, 'orientation', idxy[1]);
                circle2.setAttributeNS(null, 'id', idxy[0]);
                circle2.setAttributeNS(null, 'class', "allo");
                circle2.setAttribute("style", `cursor : ${this.state.tracerLiaison === false ? "default" : "crosshair"}`);

                circle2.addEventListener("pointerover",this.electronOver,false);
                circle2.addEventListener("pointerout",this.electronOut,false);

                group.appendChild(circle);
                group.appendChild(circle2);
                svg.appendChild(group);

            }
        });

        this.screen.appendChild(svg);

    })

    this.state.dictLiaison.forEach(element => {

        if(["EstMilieu","OuestMilieu","EstHautHaut","EstBasBas","OuestHautHaut","OuestBasBas"].includes(element.orientationStart) ){

            var line = document.createElementNS(xmlns, "line");
            line.setAttribute("x1", element.xStart);
            line.setAttribute("y1", element.yStart);
            line.setAttribute("x2", element.xEnd);
            line.setAttribute("y2", element.yEnd);
            line.setAttribute("stroke", "black");
            line.setAttribute("stroke-linecap", "round");

            switch(this.state.size){
                case 3:
                    line.setAttribute("stroke-width","11");
                    break;
                case 2:
                    line.setAttribute("stroke-width","8");
                    break;
                case 1:
                    line.setAttribute("stroke-width","6");
                    break;
            }


            line.setAttribute("id", element.IdLiaison);
            line.setAttribute("class", "liaison");
        
        }else{

            var line = document.createElementNS(xmlns, "line");
            line.setAttribute("x1", element.xStart);
            line.setAttribute("y1", element.yStart);
            line.setAttribute("x2", element.xEnd);
            line.setAttribute("y2", element.yEnd);
            line.setAttribute("stroke", "black");
            line.setAttribute("stroke-linecap", "round");
            line.setAttribute("id", element.IdLiaison);

            switch(this.state.size){
                case 3:
                    line.setAttribute("stroke-width","18");
                    break;
                case 2:
                    line.setAttribute("stroke-width","14");
                    break;
                case 1:
                    line.setAttribute("stroke-width","11");
                    break;
            }

            line.setAttribute("class", "liaison");

        }

        this.derriere.appendChild(line);
    })

  }

  async startDragElement(event){

    event.preventDefault();

    console.log(event.target.parentNode);

    this.setState({
        nbclick: this.state.nbclick += 1,
    })

    let tlimit;

    clearTimeout(tlimit);

    tlimit = setTimeout(() => {
        this.setState({ 
            nbclick : 0 
        });
    },300);

    if(event.button === 2 || this.state.nbclick >=2){

        if(event.target.parentNode.hasAttributeNS(null,"electronsids"))
        {
            await this.setState({
                contextMenu: true,
                elemAsupr: event.target.parentNode.getAttributeNS(null,"id"),
                elemAsuprX: event.target.parentNode.getAttributeNS(null,"x"),
                elemAsuprY: event.target.parentNode.getAttributeNS(null,"y"),
            }) 

        }else if(event.target.hasAttributeNS(null,"x2")){
            this.setState({
                contextMenu: true,
                elemAsupr: event.target.getAttributeNS(null,"id"),
                elemAsuprX: this.state.size === 2 ? event.target.getAttributeNS(null,"x1")-60 : this.state.size === 1 ? event.target.getAttributeNS(null,"x1")-45 : event.target.getAttributeNS(null,"x1")-75,
                elemAsuprY: this.state.size === 2 ? event.target.getAttributeNS(null,"y1")-60 : this.state.size === 1 ? event.target.getAttributeNS(null,"y1")-45 : event.target.getAttributeNS(null,"y1")-75,
            }) 
        }
    }else if(event.button === 0 && this.state.contextMenu === true && event.target.parentNode.hasAttributeNS(null,"where") === false){
        await this.setState({
            contextMenu: false,
        })
    }

    if(this.state.contextMenu === false && event.target.parentNode.hasAttributeNS(null,"where") && event.target.parentNode.getAttributeNS(null,"where") === "menuGauche" && this.state.tracerLiaison === false ){

        let element = JSON.parse(event.target.parentNode.getAttributeNS(null,"info"));
    
        element.id = uuid();
        let lesids = [];
        for(let i=0;i<element.ids.length;i++){
            let id = uuid() + String(i);
            lesids.push([id,element.ids[i][1],parseInt(element.ids[i][2]*1),parseInt(element.ids[i][3]*1)])
        }
        element.ids = lesids;

        let point = this.screen.createSVGPoint();
        if (event.touches) { event = event.touches[0]; }
        point.x = event.clientX;
        point.y = event.clientY;
        point = point.matrixTransform(this.canvas.getScreenCTM().inverse());
    
        await this.setState({
            selected: element,
            posX: this.state.size === 2 ? (point.x-60) : this.state.size === 1 ? (point.x-45) : (point.x-75),
            posY: this.state.size === 2 ? (point.y-60) : this.state.size === 1 ? (point.y-45) : (point.y-75),
            premierDeplacement : true,
        });

        var xmlns = "http://www.w3.org/2000/svg";

        var svg = document.createElementNS(xmlns, "svg");
        svg.setAttribute("id", element.id);
        svg.setAttribute("viewBox","0 0 120 120");
        
        switch(this.state.size){
            case 3:
                svg.setAttribute("width","150");
                svg.setAttribute("height","150");
                break;
            case 2:
                svg.setAttribute("width","120");
                svg.setAttribute("height","120");
                break;
            case 1:
                svg.setAttribute("width","90");
                svg.setAttribute("height","90");
                break;
        }

        svg.setAttribute("nom", element.nom);
        svg.setAttribute("symbole", element.symbole);
        svg.setAttribute("electronsids", JSON.stringify(element.ids));
        svg.setAttribute("electrons", JSON.stringify(element.electrons));
        svg.setAttribute("info", JSON.stringify(element));
        svg.setAttribute("x", this.state.posX);
        svg.setAttribute("y", this.state.posY);
        svg.setAttribute("where", "stage");
        svg.setAttribute("class", "atomeStage")
        svg.setAttribute("style", `cursor : ${this.state.tracerLiaison === false ? "pointer" : "default"}`);

        if(element.symbole.includes("plus") === true || element.symbole.includes("moins") === true){

            var path = document.createElementNS(xmlns, "text");
    
            path.setAttributeNS(null, 'x', '60');
            path.setAttributeNS(null, 'y', '65');
            path.setAttributeNS(null, 'text-anchor', 'middle');
            path.setAttributeNS(null, 'dominant-baseline', 'middle');
            path.setAttributeNS(null, 'fill', 'black');
            path.setAttributeNS(null, 'font-family', 'arial');

            switch(this.state.size){
                case 3:
                    path.setAttributeNS(null, 'font-size',"62");
                    break;
                case 2:
                    path.setAttributeNS(null, 'font-size',"60");
                    break;
                case 1:
                    path.setAttributeNS(null, 'font-size',"55");
                    break;
            }
    
            path.innerHTML = element.symbole.replace("plus","").replace("2moins","").replace("moins","");

            var path2 = document.createElementNS(xmlns, "text");
    
            path2.setAttributeNS(null, 'x', element.symbole.includes("2moins") === true ? "105" : '100');
            path2.setAttributeNS(null, 'y', element.symbole.includes("plus") === true ? "30" : "20");
            path2.setAttributeNS(null, 'text-anchor', 'middle');
            path2.setAttributeNS(null, 'dominant-baseline', 'middle');
            path2.setAttributeNS(null, 'fill', 'black');
            path2.setAttributeNS(null, 'font-family', 'arial');

            switch(this.state.size){
                case 3:
                    path2.setAttributeNS(null, 'font-size',"40");
                    break;
                case 2:
                    path2.setAttributeNS(null, 'font-size',"36");
                    break;
                case 1:
                    path2.setAttributeNS(null, 'font-size',"30");
                    break;
            }
    
            path2.innerHTML = element.symbole.includes("plus") === true ? "+" : element.symbole.includes("2moins") === true ? "2-" : element.symbole.includes("moins") === true && "-";
                        
            svg.appendChild(path);
            svg.appendChild(path2);   

        }else{

            var path = document.createElementNS(xmlns, "text");
    
            path.setAttributeNS(null, 'x', '60');
            path.setAttributeNS(null, 'y', '65');
            path.setAttributeNS(null, 'text-anchor', 'middle');
            path.setAttributeNS(null, 'dominant-baseline', 'middle');
            path.setAttributeNS(null, 'fill', 'black');
            path.setAttributeNS(null, 'font-family', 'arial');

            switch(this.state.size){
                case 3:
                    path.setAttributeNS(null, 'font-size',"62");
                    break;
                case 2:
                    path.setAttributeNS(null, 'font-size',"60");
                    break;
                case 1:
                    path.setAttributeNS(null, 'font-size',"55");
                    break;
            }
    
            path.innerHTML = element.symbole;

            svg.appendChild(path);

        }

        element.ids.forEach(idxy => {
            if(["EstMilieu","OuestMilieu","EstHautHaut","EstBasBas","OuestHautHaut","OuestBasBas"].includes(idxy[1])){

                var group = document.createElementNS(xmlns,"g");
                group.setAttribute("name","leselectrons");

                var circle = document.createElementNS(xmlns, "circle");
    
                circle.setAttributeNS(null, 'cx', idxy[2]);
                circle.setAttributeNS(null, 'cy', idxy[3]);
                circle.setAttributeNS(null, 'fill', 'black');
                circle.setAttributeNS(null, 'r', '4');
                circle.setAttributeNS(null, 'id_atome', element.id);
                circle.setAttributeNS(null, 'id_electron', idxy[0]);
                circle.setAttributeNS(null, 'orientation', idxy[1]);

                var circle2 = document.createElementNS(xmlns, "circle");
    
                circle2.setAttributeNS(null, 'cx', idxy[2]);
                circle2.setAttributeNS(null, 'cy', idxy[3]);
                circle2.setAttributeNS(null, 'fill', this.state.tracerLiaison === true ? "transparent" : "black");
                circle2.setAttributeNS(null, 'r', this.state.tracerLiaison === true ? "8" : "3");
                circle2.setAttributeNS(null, 'stroke-dasharray', '1 2');
                circle2.setAttributeNS(null, 'stroke', 'black');
                circle2.setAttributeNS(null, 'stroke-width', '1');
                circle2.setAttributeNS(null, 'id_atome', element.id);
                circle2.setAttributeNS(null, 'id_electron', idxy[0]);
                circle2.setAttributeNS(null, 'orientation', idxy[1]);
                circle2.setAttributeNS(null, 'id', idxy[0]);
                circle2.setAttributeNS(null, 'class', "allo");
                circle2.setAttribute("style", `cursor : ${this.state.tracerLiaison === false ? "default" : "crosshair"}`);

                circle2.addEventListener("pointerover",this.electronOver,false);
                circle2.addEventListener("pointerout",this.electronOut,false);

                group.appendChild(circle);
                group.appendChild(circle2);
                svg.appendChild(group);

            }else{

                var group = document.createElementNS(xmlns,"g");
                group.setAttribute("name","leselectrons");

                var circle = document.createElementNS(xmlns, "circle");
    
                circle.setAttributeNS(null, 'cx', idxy[2]);
                circle.setAttributeNS(null, 'cy', idxy[3]);
                circle.setAttributeNS(null, 'fill', 'black');
                circle.setAttributeNS(null, 'r', '7');
                circle.setAttributeNS(null, 'id_atome', element.id);
                circle.setAttributeNS(null, 'id_electron', idxy[0]);
                circle.setAttributeNS(null, 'orientation', idxy[1]);

                var circle2 = document.createElementNS(xmlns, "circle");
    
                circle2.setAttributeNS(null, 'cx', idxy[2]);
                circle2.setAttributeNS(null, 'cy', idxy[3]);
                circle2.setAttributeNS(null, 'fill', this.state.tracerLiaison === true ? "transparent" : "black");
                circle2.setAttributeNS(null, 'r', this.state.tracerLiaison === true ? "13" : "3");
                circle2.setAttributeNS(null, 'stroke-dasharray', '2 3');
                circle2.setAttributeNS(null, 'stroke', 'black');
                circle2.setAttributeNS(null, 'stroke-width', '2');
                circle2.setAttributeNS(null, 'id_atome', element.id);
                circle2.setAttributeNS(null, 'id_electron', idxy[0]);
                circle2.setAttributeNS(null, 'orientation', idxy[1]);
                circle2.setAttributeNS(null, 'id', idxy[0]);
                circle2.setAttributeNS(null, 'class', "allo");
                circle2.setAttribute("style", `cursor : ${this.state.tracerLiaison === false ? "default" : "crosshair"}`);

                circle2.addEventListener("pointerover",this.electronOver,false);
                circle2.addEventListener("pointerout",this.electronOut,false);

                group.appendChild(circle);
                group.appendChild(circle2);
                svg.appendChild(group);

            }
        });

        this.screen.appendChild(svg);

      }else if(this.state.contextMenu === false && event.target.parentNode.hasAttributeNS(null,"where") && event.target.parentNode.getAttributeNS(null,"where") === "stage" && this.state.nbclick < 2  && this.state.tracerLiaison === false ){

            console.log(event.target.parentNode);

            let element = event.target.parentNode;

            let info = JSON.parse(element.getAttributeNS(null,"info"));

            let chercher = this.chercherLiaisons(info);

            this.setState({
                selected : info,
                listeAtomes : this.state.listeAtomes.filter(e => e.id !== element.getAttributeNS(null,"id")),
                liaisonsSelected : chercher[0],
                dictLiaison : chercher[1],
            })

      }else if(this.state.contextMenu === false && event.target.hasAttributeNS(null,"id_electron")  && this.state.tracerLiaison === true ){

            let element = event.target;

            let idLiaison = uuid();

            let parent = event.target.parentNode;

            await this.setState({
                LiaisonBegin : true,
                LiaisonStartX: this.state.size === 2 ? parseFloat(event.target.getAttributeNS(null,"cx"))+parseFloat(parent.parentNode.getAttributeNS(null,"x")) : this.state.size === 1 ? parseFloat(event.target.getAttributeNS(null,"cx")*3/4)+parseFloat(parent.parentNode.getAttributeNS(null,"x")) : parseFloat(event.target.getAttributeNS(null,"cx")*1.25)+parseFloat(parent.parentNode.getAttributeNS(null,"x")),
                LiaisonStartY: this.state.size === 2 ? parseFloat(event.target.getAttributeNS(null,"cy"))+parseFloat(parent.parentNode.getAttributeNS(null,"y")) : this.state.size === 1 ? parseFloat(event.target.getAttributeNS(null,"cy")*3/4)+parseFloat(parent.parentNode.getAttributeNS(null,"y")) : parseFloat(event.target.getAttributeNS(null,"cy")*1.25)+parseFloat(parent.parentNode.getAttributeNS(null,"y")),
                LiaisonEndX: this.state.size === 2 ? parseFloat(event.target.getAttributeNS(null,"cx"))+parseFloat(parent.parentNode.getAttributeNS(null,"x")) : this.state.size === 1 ? parseFloat(event.target.getAttributeNS(null,"cx")*3/4)+parseFloat(parent.parentNode.getAttributeNS(null,"x")) : parseFloat(event.target.getAttributeNS(null,"cx")*1.25)+parseFloat(parent.parentNode.getAttributeNS(null,"x")),
                LiaisonEndY: this.state.size === 2 ? parseFloat(event.target.getAttributeNS(null,"cy"))+parseFloat(parent.parentNode.getAttributeNS(null,"y")) : this.state.size === 1 ? parseFloat(event.target.getAttributeNS(null,"cy")*3/4)+parseFloat(parent.parentNode.getAttributeNS(null,"y")) : parseFloat(event.target.getAttributeNS(null,"cy")*1.25)+parseFloat(parent.parentNode.getAttributeNS(null,"y")),
                LaisonStartIdElectron: event.target.getAttributeNS(null, 'id_electron'),
                LaisonStartIdAtome: event.target.getAttributeNS(null, 'id_atome'),
                orientationStart : event.target.getAttributeNS(null, 'orientation'),
                idLiaison : idLiaison,
            })

            var xmlns = "http://www.w3.org/2000/svg";

            if(["EstMilieu","OuestMilieu","EstHautHaut","EstBasBas","OuestHautHaut","OuestBasBas"].includes(event.target.getAttribute("orientation"))){

                var line = document.createElementNS(xmlns, "line");
                line.setAttribute("x1", this.state.LiaisonStartX);
                line.setAttribute("y1", this.state.LiaisonStartY);
                line.setAttribute("x2", this.state.LiaisonStartX);
                line.setAttribute("y2", this.state.LiaisonStartY);
                line.setAttribute("stroke", "black");
                line.setAttribute("stroke-linecap", "round");
                
                switch(this.state.size){
                    case 3:
                        line.setAttribute("stroke-width","11");
                        break;
                    case 2:
                        line.setAttribute("stroke-width","8");
                        break;
                    case 1:
                        line.setAttribute("stroke-width","6");
                        break;
                }

                line.setAttribute("id", idLiaison);
                line.setAttribute("class", "liaison");
            
            }else{

                var line = document.createElementNS(xmlns, "line");
                line.setAttribute("x1", this.state.LiaisonStartX);
                line.setAttribute("y1", this.state.LiaisonStartY);
                line.setAttribute("x2", this.state.LiaisonStartX);
                line.setAttribute("y2", this.state.LiaisonStartY);
                line.setAttribute("stroke", "black");
                line.setAttribute("stroke-linecap", "round");
                line.setAttribute("id", idLiaison);

                switch(this.state.size){
                    case 3:
                        line.setAttribute("stroke-width","18");
                        break;
                    case 2:
                        line.setAttribute("stroke-width","14");
                        break;
                    case 1:
                        line.setAttribute("stroke-width","11");
                        break;
                }

                line.setAttribute("class", "liaison");

            }

            this.derriere.appendChild(line);

      }

  }

  chercherLiaisons(selection){

    //console.log("fonction chercherLiaisons");

  let lesliaisons = [...this.state.dictLiaison];

  let id = selection.id;

  let selected = lesliaisons.filter(elem => id === elem.IdAtomeStart || id === elem.IdAtomeEnd );

  let lereste = lesliaisons.filter(elem => (id === elem.IdAtomeStart || id === elem.IdAtomeEnd) === false );

  selected.forEach(e => {
        if(id === e.IdAtomeStart){
            e.position = "start"
        }else if(id === e.IdAtomeEnd){
            e.position = "end"
        }
  });

  return [selected,lereste];
}

  electronOver(event){

    event.preventDefault();

    console.log(event.target);

    if( this.state.tracerLiaison === true)
    {

        event.target.setAttributeNS(null, "stroke", "green");
     
        if(["EstMilieu","OuestMilieu","EstHautHaut","EstBasBas","OuestHautHaut","OuestBasBas"].includes(event.target.getAttribute("orientation"))){
            event.target.setAttributeNS(null, 'stroke-width', '3'); 
        }else{
            event.target.setAttributeNS(null, 'stroke-width', '4'); 
        }

        let selection = event.target.parentNode.children[0];

        selection.setAttributeNS(null, "fill", "green");
        selection.setAttributeNS(null, "stroke", "green");

    }

  }

  electronOut(event){

    event.preventDefault();

    if( this.state.tracerLiaison === true)
      {
            
            event.target.setAttributeNS(null, "stroke", "black");            
            
            if(["EstMilieu","OuestMilieu","EstHautHaut","EstBasBas","OuestHautHaut","OuestBasBas"].includes(event.target.getAttribute("orientation"))){
                event.target.setAttributeNS(null, 'stroke-width', '1'); 
            }else{
                event.target.setAttributeNS(null, 'stroke-width', '2');
            }

            let selection = event.target.parentNode.children[0];

            selection.setAttributeNS(null, "fill", "black");
            selection.setAttributeNS(null, "stroke", "black");
      }

  }

  changerY(newY){
      this.setState({
          y : newY,
      })
  }

  async DragElement(event){

    event.preventDefault();

    let point = this.screen.createSVGPoint();
    if (event.touches) { event = event.touches[0]; }
    point.x = event.clientX;
    point.y = event.clientY;
    point = point.matrixTransform(this.screen.getScreenCTM().inverse());

    
    if( this.state.selected !== null && this.state.tracerLiaison === false ){
        await this.setState({
            posX: this.state.size === 2 ? (point.x-60) : this.state.size === 1 ? (point.x-45) : (point.x-75),
            posY: this.state.size === 2 ? (point.y-60) : this.state.size === 1 ? (point.y-45) : (point.y-75),
        });

        let element = document.getElementById(this.state.selected.id);

        element.setAttributeNS(null, 'x', this.state.posX);
        element.setAttributeNS(null, 'y', this.state.posY);

        let lesliaisonSelected = this.state.liaisonsSelected;
                  
            if(lesliaisonSelected.length > 0){
                
                lesliaisonSelected.forEach(e => {
                let elem = e;

                let lili = document.getElementById(e.IdLiaison);
 
                if(e.IdAtomeStart !== e.IdAtomeEnd)
                {
                    if(e.position === "end"){
                        elem.xEnd = parseFloat(this.state.posX)+parseFloat(this.getXYOrientation(e.orientationEnd)[0]);
                        elem.yEnd = parseFloat(this.state.posY)+parseFloat(this.getXYOrientation(e.orientationEnd)[1]);
                    
                    }else if(e.position === "start"){
                        elem.xStart = parseFloat(this.state.posX)+parseFloat(this.getXYOrientation(e.orientationStart)[0]);
                        elem.yStart = parseFloat(this.state.posY)+parseFloat(this.getXYOrientation(e.orientationStart)[1]);
                       
                    }
                }else
                {
                        elem.xStart = parseFloat(this.state.posX)+parseFloat(this.getXYOrientation(e.orientationStart)[0]);
                        elem.yStart = parseFloat(this.state.posY)+parseFloat(this.getXYOrientation(e.orientationStart)[1]);
                        elem.xEnd = parseFloat(this.state.posX)+parseFloat(this.getXYOrientation(e.orientationEnd)[0]);
                        elem.yEnd = parseFloat(this.state.posY)+parseFloat(this.getXYOrientation(e.orientationEnd)[1]);
                }

                    lili.setAttribute("x1",elem.xStart);
                    lili.setAttribute("y1",elem.yStart);

                    lili.setAttribute("x2",elem.xEnd);
                    lili.setAttribute("y2",elem.yEnd);
                
                });

                await this.setState({
                    liaisonsSelected : lesliaisonSelected,
                });
            }

    }else if(this.state.LiaisonBegin === true){
        await this.setState({
            LiaisonEndX: point.x,
            LiaisonEndY: point.y,
        });

        let laliaison = document.getElementById(this.state.idLiaison);

        laliaison.setAttributeNS(null,"x2",this.state.LiaisonEndX);
        laliaison.setAttributeNS(null,"y2",this.state.LiaisonEndY);
    }

  }

  async stopDragElement(event){

    event.preventDefault();

    console.log(event.target);

    if(this.state.selected !== null)
    {
        let atome = await { ...this.state.selected };

            atome.x = this.state.posX;
            atome.y = this.state.posY;

            if(this.state.premierDeplacement === true)
            {
                if( this.state.posX > -545 ){

                    let element = document.getElementById(this.state.selected.id);

                    element.setAttributeNS(null, 'x', this.state.posX);
                    element.setAttributeNS(null, 'y', this.state.posY);

                    await this.setState({
                        listeAtomes : [...this.state.listeAtomes,atome],
                        selected : null,
                        premierDeplacement : false,
                    });
                }else{

                    let atomedelete = document.getElementById(this.state.selected.id);

                    this.screen.removeChild(atomedelete);

                    this.setState({
                        selected : null,
                        premierDeplacement : false,
                    });

                }
            }
            else
            {
                let element = document.getElementById(this.state.selected.id);

                element.setAttributeNS(null, 'x', this.state.posX);
                element.setAttributeNS(null, 'y', this.state.posY);

                await this.setState({
                    listeAtomes : [...this.state.listeAtomes,atome],
                    selected : null
                });
            }

            if(this.state.liaisonsSelected.length !== 0){
                await this.setState({
                    dictLiaison : [...this.state.dictLiaison, ...this.state.liaisonsSelected],
                    liaisonsSelected: [],
                    lesidvisite : []
                })

            }

            await this.updateGraphe();

            let nb = 0;

            while( this.state.listeAtomes.length > this.state.visite.length ){
                await this.parcours_profondeur(this.state.graphe,atome.id,this.state.visite);
                nb += 1;
            }
            
            this.setState({
                nombremolecule : nb,
                visite : [],
            }) 
    }
    else if(this.state.LiaisonBegin === true)
    {
        console.log(event.target);

        if(event.target.hasAttribute("id_electron"))
        {
            let target;

            if(event.type === "touchend"){
                var myLocation = event.changedTouches[0];
                target = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
                console.log(target);
            }else{
                target = event.target;
            }

            await this.setState({
                IdElectronEnd : target.getAttributeNS(null,'id_electron'),
                IdAtomeEnd : target.getAttributeNS(null,'id_atome'),
              })

              let ok = false;

              let valide = true;

              if(this.state.IdAtomeEnd === this.state.LaisonStartIdAtome){

                  if( this.state.orientationStart === "NordDroit" && target.getAttributeNS(null, 'orientation') === "NordGauche" ){
                      ok = true;
                  }else if( this.state.orientationStart === "NordGauche" && target.getAttributeNS(null, 'orientation') === "NordDroit" ){
                      ok = true;
                  }else if( this.state.orientationStart === "SudDroit" && target.getAttributeNS(null, 'orientation') === "SudGauche" ){
                      ok = true;
                  }else if( this.state.orientationStart === "SudGauche" && target.getAttributeNS(null, 'orientation') === "SudDroit" ){
                      ok = true;
                  }else if( this.state.orientationStart === "EstHaut" && target.getAttributeNS(null, 'orientation') === "EstBas" ){
                      ok = true;
                  }else if( this.state.orientationStart === "EstBas" && target.getAttributeNS(null, 'orientation') === "EstHaut" ){
                      ok = true;
                  }else if( this.state.orientationStart === "OuestHaut" && target.getAttributeNS(null, 'orientation') === "OuestBas" ){
                      ok = true;
                  }else if( this.state.orientationStart === "OuestBas" && target.getAttributeNS(null, 'orientation') === "OuestHaut" ){
                      ok = true;
                  }else if( this.state.orientationStart === "OuestHaut" && target.getAttributeNS(null, 'orientation') === "NordGauche" ){
                      ok = true;
                  }else if( this.state.orientationStart === "NordGauche" && target.getAttributeNS(null, 'orientation') === "OuestHaut" ){
                      ok = true;
                  }else if( this.state.orientationStart === "SudGauche" && target.getAttributeNS(null, 'orientation') === "OuestBas" ){
                      ok = true;
                  }else if( this.state.orientationStart === "OuestBas" && target.getAttributeNS(null, 'orientation') === "SudGauche" ){
                      ok = true;
                  }else if( this.state.orientationStart === "EstHaut" && target.getAttributeNS(null, 'orientation') === "NordDroit" ){
                      ok = true;
                  }else if( this.state.orientationStart === "NordDroit" && target.getAttributeNS(null, 'orientation') === "EstHaut" ){
                      ok = true;
                  }else if( this.state.orientationStart === "EstBas" && target.getAttributeNS(null, 'orientation') === "SudDroit" ){
                      ok = true;
                  }else if( this.state.orientationStart === "SudDroit" && target.getAttributeNS(null, 'orientation') === "EstBas" ){
                      ok = true;
                  }else if( this.state.orientationStart === "EstHaut" && target.getAttributeNS(null, 'orientation') === "Nord" ){
                      ok = true;
                  }else if( this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "EstHaut" ){
                      ok = true;
                  }else if( this.state.orientationStart === "OuestHaut" && target.getAttributeNS(null, 'orientation') === "Nord" ){
                      ok = true;
                  }else if( this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "EstHaut" ){
                      ok = true;
                  }else if( this.state.orientationStart === "EstHaut" && target.getAttributeNS(null, 'orientation') === "Nord" ){
                      ok = true;
                  }else if( this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "EstHaut" ){
                      ok = true;
                  }else if( this.state.orientationStart === "Sud" && target.getAttributeNS(null, 'orientation') === "EstBas" ){
                      ok = true;
                  }else if( this.state.orientationStart === "EstBas" && target.getAttributeNS(null, 'orientation') === "Sud" ){
                      ok = true;
                  }else if( this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "OuestHaut" ){
                      ok = true;
                  }else if( this.state.orientationStart === "OuestHaut" && target.getAttributeNS(null, 'orientation') === "Nord" ){
                      ok = true;
                  }else if( this.state.orientationStart === "Sud" && target.getAttributeNS(null, 'orientation') === "OuestBas" ){
                      ok = true;
                  }else if( this.state.orientationStart === "OuestBas" && target.getAttributeNS(null, 'orientation') === "Sud" ){
                      ok = true;
                  }else if( this.state.orientationStart === "Ouest" && target.getAttributeNS(null, 'orientation') === "NordGauche" ){
                      ok = true;
                  }else if( this.state.orientationStart === "NordGauche" && target.getAttributeNS(null, 'orientation') === "Ouest" ){
                      ok = true;
                  }else if( this.state.orientationStart === "Est" && target.getAttributeNS(null, 'orientation') === "NordDroit" ){
                      ok = true;
                  }else if( this.state.orientationStart === "NordDroit" && target.getAttributeNS(null, 'orientation') === "Est" ){
                      ok = true;
                  }else{
                      ok = false;
                  }

              }else{

                let cotedroit =["Est","EstHaut","EstHautHaut","EstBas","EstBasBas","EstMilieu"];
                let cotehaut = ["Nord","NordGauche","NordDroit"]

                this.state.dictLiaison.forEach( e => {
                    if(e.IdAtomeStart === this.state.LaisonStartIdAtome && e.IdAtomeEnd === this.state.IdAtomeEnd){
                        // on teste si la liaison est du même côté  

                        //console.log("orientation start : " + e.orientationStart);
                        //console.log("debut : " + this.state.orientationStart);

                        if( cotedroit.includes(e.orientationStart) === true && cotedroit.includes(this.state.orientationStart) === false ){
                            valide = false;
                        }else if( cotedroit.includes(e.orientationStart) === true && cotedroit.includes(this.state.orientationStart) === false ){
                            valide = false;
                        }

                        if( cotehaut.includes(e.orientationStart) === true && cotehaut.includes(this.state.orientationStart) === false ){
                            valide = false;
                        }else if( cotehaut.includes(e.orientationStart) === false && cotehaut.includes(this.state.orientationStart) === true ){
                            valide = false;
                        }

                    }else if(e.IdAtomeStart === this.state.IdAtomeEnd && e.IdAtomeEnd === this.state.LaisonStartIdAtome){
                        // on teste si la liaison est du même côté  

                        //console.log("orientation start : " + e.orientationStart);
                        //console.log("debut : " + this.state.orientationEnd);

                        if( cotedroit.includes(e.orientationEnd) === true  && cotedroit.includes(this.state.orientationStart) === false ){
                            valide = false;
                        }else if( cotedroit.includes(e.orientationEnd) === false && cotedroit.includes(this.state.orientationStart) === true ){
                            valide = false;
                        }

                        if( cotehaut.includes(e.orientationEnd) === true  && cotehaut.includes(this.state.orientationStart) === false ){
                            valide = false;
                        }else if( cotehaut.includes(e.orientationEnd) === false && cotehaut.includes(this.state.orientationStart) === true ){
                            valide = false;
                        }

                    }
                    
                });

                console.log("valide : " + valide);

                if(valide === true)
                {
                    if( this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "Sud" ){
                        ok = true;
                    }else if( this.state.orientationStart === "Sud" && target.getAttributeNS(null, 'orientation') === "Nord" ){
                        ok = true;
                    }else if( this.state.orientationStart === "NordDroit" && target.getAttributeNS(null, 'orientation') === "SudDroit" ){
                        ok = true;
                    }else if( this.state.orientationStart === "SudDroit" && target.getAttributeNS(null, 'orientation') === "NordDroit" ){
                        ok = true;
                    }else if( this.state.orientationStart === "NordGauche" && target.getAttributeNS(null, 'orientation') === "SudGauche" ){
                        ok = true;
                    }else if( this.state.orientationStart === "SudGauche" && target.getAttributeNS(null, 'orientation') === "NordGauche" ){
                        ok = true;
                    }else if( this.state.orientationStart === "Est" && target.getAttributeNS(null, 'orientation') === "Ouest" ){
                        ok = true;
                    }else if( this.state.orientationStart === "Ouest" && target.getAttributeNS(null, 'orientation') === "Est" ){
                        ok = true;
                    }else if( (this.state.orientationStart === "Est" && target.getAttributeNS(null, 'orientation') === "Nord") || (this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "Est") || (this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "Ouest")  || (this.state.orientationStart === "Ouest" && target.getAttributeNS(null, 'orientation') === "Nord")){
                        ( ["H","Cl"].includes(document.getElementById(this.state.LaisonStartIdAtome).getAttribute("symbole")) || ["H","Cl"].includes(target.getAttributeNS(null,"symbole")) ) ? ok = false : ok = true;
                    }else if( (this.state.orientationStart === "Est" && target.getAttributeNS(null, 'orientation') === "Sud") || (this.state.orientationStart === "Sud" && target.getAttributeNS(null, 'orientation') === "Est") || (this.state.orientationStart === "Ouest" && target.getAttributeNS(null, 'orientation') === "Sud") || (this.state.orientationStart === "Sud" && target.getAttributeNS(null, 'orientation') === "Ouest") ){
                        ( ["H","Cl"].includes(document.getElementById(this.state.LaisonStartIdAtome).getAttribute("symbole")) || ["H","Cl"].includes(target.getAttributeNS(null,"symbole")) ) ? ok = false : ok = true;
                    }else if(this.state.orientationStart === "EstHaut" && target.getAttributeNS(null, 'orientation') === "OuestHaut"){
                        ok = true;
                    }else if( this.state.orientationStart === "OuestHaut" && target.getAttributeNS(null, 'orientation') === "EstHaut" ){
                        ok = true;
                    }else if( this.state.orientationStart === "EstBas" && target.getAttributeNS(null, 'orientation') === "OuestBas" ){
                        ok = true;
                    }else if( this.state.orientationStart === "OuestBas" && target.getAttributeNS(null, 'orientation') === "EstBas" ){
                        ok = true;
                    }else if( this.state.orientationStart === "EstHautHaut" && target.getAttributeNS(null, 'orientation') === "OuestHautHaut" ){
                        ok = true;
                    }else if( this.state.orientationStart === "OuestHautHaut" && target.getAttributeNS(null, 'orientation') === "EstHautHaut" ){
                        ok = true;
                    }else if( this.state.orientationStart === "EstMilieu" && target.getAttributeNS(null, 'orientation') === "OuestMilieu" ){
                        ok = true;
                    }else if( this.state.orientationStart === "OuestMilieu" && target.getAttributeNS(null, 'orientation') === "EstMilieu" ){
                        ok = true;
                    }else if( this.state.orientationStart === "EstBasBas" && target.getAttributeNS(null, 'orientation') === "OuestBasBas" ){
                        ok = true;
                    }else if( this.state.orientationStart === "OuestBasBas" && target.getAttributeNS(null, 'orientation') === "EstBasBas" ){
                        ok = true;
                    }else if( this.state.orientationStart === "EstHaut" && event.target.getAttributeNS(null, 'orientation') === "Sud" ){
                        ok = true;
                    }else if( this.state.orientationStart === "Sud" && target.getAttributeNS(null, 'orientation') === "EstHaut" ){
                        ok = true;
                    }else if( this.state.orientationStart === "Sud" && target.getAttributeNS(null, 'orientation') === "OuestHaut" ){
                        ok = true;
                    }else if( this.state.orientationStart === "OuestHaut" && target.getAttributeNS(null, 'orientation') === "Sud" ){
                        ok = true;
                    }else if( this.state.orientationStart === "EstBas" && target.getAttributeNS(null, 'orientation') === "Nord" ){
                        ok = true;
                    }else if( this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "EstBas" ){
                        ok = true;
                    }else if( this.state.orientationStart === "Nord" && target.getAttributeNS(null, 'orientation') === "OuestBas" ){
                        ok = true;
                    }else if( this.state.orientationStart === "OuestBas" && target.getAttributeNS(null, 'orientation') === "Nord" ){
                        ok = true;
                    }else{
                        ok = false;
                        console.log("false 2");
                    }
                }

              }

              if(ok === true && valide === true){

                    let trouve = this.state.dictLiaison.filter(elem => ( elem.IdElectronStart === this.state.LaisonStartIdElectron || elem.IdElectronEnd === this.state.LaisonStartIdElectron ) || ( elem.IdElectronStart === this.state.IdElectronEnd || elem.IdElectronEnd === this.state.IdElectronEnd ) ).length;

                    if( trouve === 0 ){

                            let atomeEnd = this.state.listeAtomes.find(elem => elem.id === this.state.IdAtomeEnd);

                            await this.setState({
                                dictLiaison: [...this.state.dictLiaison, {
                                    IdElectronStart : this.state.LaisonStartIdElectron,
                                    IdAtomeStart : this.state.LaisonStartIdAtome,
                                    xStart : this.state.LiaisonStartX,
                                    yStart : this.state.LiaisonStartY,
                                    IdElectronEnd : this.state.IdElectronEnd,
                                    IdAtomeEnd : this.state.IdAtomeEnd,
                                    xEnd : this.state.size === 2 ? parseFloat(target.getAttributeNS(null,"cx"))+parseFloat(atomeEnd.x) : this.state.size === 1 ? parseFloat(target.getAttributeNS(null,"cx")*3/4)+parseFloat(atomeEnd.x) : parseFloat(target.getAttributeNS(null,"cx")*1.25)+parseFloat(atomeEnd.x),
                                    yEnd : this.state.size === 2 ? parseFloat(target.getAttributeNS(null,"cy"))+parseFloat(atomeEnd.y) : this.state.size === 1 ? parseFloat(target.getAttributeNS(null,"cy")*3/4)+parseFloat(atomeEnd.y) : parseFloat(target.getAttributeNS(null,"cy")*1.25)+parseFloat(atomeEnd.y),
                                    orientationStart : this.state.orientationStart,
                                    orientationEnd : target.getAttributeNS(null, 'orientation'),
                                    IdLiaison : this.state.idLiaison,
                                }],
                                LiaisonBegin : false,
                                orientationStart: null
                            })

                            await this.setState({
                                lesidvisite : []
                            })


                            await this.updateGraphe(); 

                            let nb = 0;

                            while( this.state.listeAtomes.length > this.state.visite.length ){
                                await this.parcours_profondeur(this.state.graphe,this.state.LaisonStartIdAtome,this.state.visite);
                                nb += 1;
                            }

                            await this.updateStageAtomes(false);
                            
                            this.setState({
                                nombremolecule : nb,
                                visite : [],
                            })  

                    }else{
                        this.setState({
                            error1 : true,
                            LiaisonBegin : false,
                          })

                        let element = document.getElementById(this.state.idLiaison);
                        this.derriere.removeChild(element);
                        }

              }else{
                this.setState({
                    error2 : true,
                    LiaisonBegin : false,
                  })
                let element = document.getElementById(this.state.idLiaison);
                this.derriere.removeChild(element);
              }

          }else{
            this.setState({
              LiaisonBegin : false,
            })
            let element = document.getElementById(this.state.idLiaison);
            this.derriere.removeChild(element);
          }
    }

  }


  contextMenuInit(event,e){
      event.preventDefault();
  }


  showInfo(e){
      this.setState({
          info : !this.state.info,
      })

      if(this.state.help === true){
        this.setState({
            help : false
        })
      }
  }

  showHelp(e){
    this.setState({
        help : !this.state.help,
    })

    if(this.state.info === true){
        this.setState({
            info : false
        })
    }
  }

  DeleteAll(e){

    this.state.listeAtomes.forEach(e => {
        this.screen.removeChild(document.getElementById(e.id));
    });

    while (this.derriere.firstChild) {
        this.derriere.removeChild(this.derriere.firstChild);
    }

    this.setState({
        selected : null,
        liaisonsSelected: [],
        dictLiaison: [],
        listeAtomes: [],
        graphe: null,
        nombremolecule : 0,
    })
  }

  async changeAllo(changer){

    await this.setState({
        tracerLiaison: changer,
    })

    let lesallos = document.getElementsByClassName("allo");

    for (let i = 0; i < lesallos.length; i++) {
        if(["EstMilieu","OuestMilieu","EstHautHaut","EstBasBas","OuestHautHaut","OuestBasBas"].includes(lesallos[i].getAttribute("orientation"))){
            lesallos[i].setAttributeNS(null, 'fill', this.state.tracerLiaison === true ? "transparent" : "black");
            lesallos[i].setAttributeNS(null, 'r', this.state.tracerLiaison === true ? "8" : "3");
        }else{
            lesallos[i].setAttributeNS(null, 'fill', this.state.tracerLiaison === true ? "transparent" : "black");
            lesallos[i].setAttributeNS(null, 'r', this.state.tracerLiaison === true ? "13" : "3");
        }
    }

    let lesatomes = document.getElementsByClassName("atomeStage");

    for (let i = 0; i < lesatomes.length; i++) {
        lesatomes[i].setAttribute("style", `cursor : ${this.state.tracerLiaison === false ? "pointer" : "default"}`);
    }

  }

  async validate(){
    
    await this.updateGraphe();

    let graphe = [...this.state.graphe];

    if(graphe.length > 0){

    let valide = true;

    for (var [noeud, voisin] of graphe) {
        let nomNoeud = this.state.listeAtomes.find(elem => elem.id === noeud).symbole;
        let nombreElectronNonLiant = voisin.filter(e => e ===noeud ).length;
        let nombreElectronLiant = voisin.length-nombreElectronNonLiant;
        if(lesinfosAtomes[nomNoeud][0] !== nombreElectronLiant){
            valide = false;
            break;
        }
        if(lesinfosAtomes[nomNoeud][1] !== nombreElectronNonLiant){
            valide = false;
            break;
        }
    }

    if(valide === true){
        this.setState({
            estjuste : true,
            afficher : true,
        })
    }else{
        this.setState({
            estjuste : false,
            afficher : true,
        })
    }

    }else{
        console.log("aucun atomes");
    }

  }

  showJuste(){
      this.setState({
          afficher: !this.state.afficher,
      })
  }

  showError(){
      this.setState({
          error1 : false,
          error2 : false,
      })
  }

  async zoomPlus(){
    if(this.state.size < 3)
    {
        await this.setState({
            size : parseInt(this.state.size+1),
        })

        await this.updateGraphe();

        while( this.state.listeAtomes.length > this.state.visite.length ){

            let max = 0;
            let idmax = null;

            for(let [key, value] of this.state.graphe){
                if(value.length > max && this.state.visite.includes(key) === false ){
                    max = value.length;
                    idmax = key;
                }
            }

            await this.parcours_profondeur(this.state.graphe,idmax,this.state.visite);
        }

        await this.updateStageAtomes(false);
        
        this.setState({
            visite : [],
        }) 
    }
  }

  async zoomMoins(){
    if(this.state.size > 1)
    {
        await this.setState({
            size : parseInt(this.state.size-1),
        })

        await this.updateGraphe();

        while( this.state.listeAtomes.length > this.state.visite.length ){

            let max = 0;
            let idmax = null;

            for(let [key, value] of this.state.graphe){
                if(value.length > max && this.state.visite.includes(key) === false ){
                    max = value.length;
                    idmax = key;
                }
            }

            await this.parcours_profondeur(this.state.graphe,idmax,this.state.visite);
        }

        await this.updateStageAtomes(false);
        
        this.setState({
            visite : [],
        })
    }  
  }

  async Delete(e){

    let elemdelete = document.getElementById(this.state.elemAsupr);

    this.state.dictLiaison.forEach(e => {
        if(e.IdAtomeStart === elemdelete.id || e.IdAtomeEnd === elemdelete.id){
            this.derriere.removeChild(document.getElementById(e.IdLiaison));
        }       
    })

    if(elemdelete.hasAttribute("x2")){
        this.derriere.removeChild(elemdelete);
    }else{
        this.screen.removeChild(elemdelete);
    }


    await this.setState({
        dictLiaison : this.state.dictLiaison.filter(e => this.state.elemAsupr !== e.IdLiaison && e.IdAtomeStart !== this.state.elemAsupr && e.IdAtomeEnd !== this.state.elemAsupr ),
        listeAtomes :this.state.listeAtomes.filter(e => this.state.elemAsupr !== e.id),
        liaisonsSelected : [],
        elemAsupr: null,
        contextMenu: false,
    })
    
    await this.updateGraphe();

    let nb = 0;

    while( this.state.listeAtomes.length > this.state.visite.length ){

        let max = 0;
        let idmax = null;

        for(let [key, value] of this.state.graphe){
            if(value.length > max && this.state.visite.includes(key) === false ){
                max = value.length;
                idmax = key;
            }
        }

        await this.parcours_profondeur(this.state.graphe,idmax,this.state.visite);
        nb += 1;
    }
    
    this.setState({
        nombremolecule : nb,
        visite : [],
    })  

  }

  savePicture(){
    const length = 6;
    const str = Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
    saveSvgAsPng.saveSvgAsPng(document.getElementById("picture"), `${str}.png`, imageOptions);
  }
    
  render(){
    return (
      <div className="svg-container">
        <svg onContextMenu={this.contextMenuInit} id="container" ref={(e) => this.canvas = e} viewBox="-625 -384 1250 768" className="svg-content"  style={{ cursor: this.state.tracerLiaison === true ? "crosshair"  : "default"}}>      
          <MenuGauche changerY={this.changerY} liaison={this.state.tracerLiaison} atomes={atomesInit} startDragStart={this.startDragStart}/>
          <svg x="-625" y="-384" viewBox="-625 -384 1250 768"  width="100%" height="100%" ref={(e) => this.screen = e} id="picture">
            <g ref={(e) => this.derriere = e}>

            </g>
            <RightClick size={this.state.size} contextMenu={this.state.contextMenu} x={this.state.elemAsuprX} y={this.state.elemAsuprY} Delete={this.Delete}/>
          </svg>
          <MenuDroite size={this.state.size} save={this.savePicture} zoomPlus={this.zoomPlus} zoomMoins={this.zoomMoins} showInfo={this.showInfo} changeAllo={this.changeAllo} showHelp={this.showHelp} DeleteAll={this.DeleteAll}/>
          <Valider nombremolecule={this.state.nombremolecule} Validate={this.validate}/>
          <Juste nombremolecule={this.state.nombremolecule} juste={this.state.estjuste} afficher={this.state.afficher} showJuste={this.showJuste}/>
          <Error error1={this.state.error1} error2={this.state.error2} showError={this.showError}/>
          <Info info={this.state.info} showInfo={this.showInfo}/>
          <Aide help={this.state.help} showHelp={this.showHelp}/>
        </svg>
      </div>
    );
  }

}

export default App;
