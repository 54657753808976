import React, { Fragment,useState, useEffect } from 'react';
import {ReactComponent as ReactDelete} from '../images/new.svg';
import {ReactComponent as ReactDraw} from '../images/draw.svg';
import {ReactComponent as ReactInfo} from '../images/info.svg';
import {ReactComponent as ReactHelp} from '../images/help.svg';
import {ReactComponent as ReactPlus} from '../images/zoom.svg';
import {ReactComponent as ReactMoins} from '../images/dezoom.svg';
import {ReactComponent as ReactSave} from '../images/save.svg';

const MenuDroite = (props) => {

    const [buttonColor, setbuttonColor] = useState("#7b7b7b")
    const [colorButon, setcolorButon] = useState("#7b7b7b")
    const [colorplus, setcolorplus] = useState("#7b7b7b")
    const [colormoins, setcolormoins] = useState("#7b7b7b")


    const handleClick = (e) => {
        setbuttonColor( buttonColor === "#7b7b7b" ? "green" : "#7b7b7b");
        props.changeAllo( buttonColor === "#7b7b7b" ? true : false);
    }

    const handleClickInfo = (e) => {
        e.preventDefault();
        props.showInfo(e);
    }

    const handleClickHelp = (e) => {
        e.preventDefault();
        props.showHelp(e);
    }

    const handleClickDelete = (e) => {
        e.preventDefault();
        props.DeleteAll(e);
    }

    const handleClickPlus = (e) => {
        e.preventDefault();

        if(colorplus !== "green"){
            setcolorplus("green");
            props.zoomPlus();
        }else{
            setcolorplus("#7b7b7b");
        }
        
    }

    const handleClickMoins = (e) => {
        e.preventDefault();

        if(colormoins !== "green"){
            setcolormoins("green");
            props.zoomMoins();
        }else{
            setcolormoins("#7b7b7b");
        }
        
    }

    const  handleSave = (e) => {
        e.preventDefault();
        if(colorButon !== "green"){
            setcolorButon("green");
            props.save();
        }else{
            setcolorButon("#7b7b7b");
        }
        
    }

    return (
        <Fragment>
            <rect x="545" y="-360" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="545" y="-350" fill="black" width="60" height="10"/>

            <svg id="menuDroite" x="545" y="-340" width="60" height="120">

                <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%"/>

                <svg x="0" y="0" height="60" width="60" onPointerDown={handleClick} style={{"cursor" : "pointer"}}>
                    <rect x="0" y="0" fill={buttonColor} width="100%" height="100%"/>
                    <ReactDraw height="40" width="40" x="10" y="10"/>
                </svg>

                <svg x="0" y="60" height="60" width="60" onPointerDown={handleClickDelete} style={{"cursor" : "pointer"}}>
                    <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%"/>
                    <ReactDelete height="40" width="40" x="10" y="10"/>
                </svg>

                <line x1="0" y1="60"
                    x2="60" y2="60" 
                    stroke="black" 
                    strokeWidth="2"/>
            </svg>

            <rect x="545" y="-220" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="545" y="-220" fill="black" width="60" height="10"/>



            <rect x="545" y="-160" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="545" y="-150" fill="black" width="60" height="10"/>

            <svg id="menuDroite2" x="545" y="-140" width="60" height="120">
                <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%"/>
                
                <svg x="0" y="0" height="60" width="60" onPointerDown={handleClickPlus} onPointerUp={handleClickPlus} style={{"cursor" : "pointer"}}>
                    <rect x="0" y="0" fill={colorplus} width="100%" height="100%"/>
                    <ReactPlus height="40" width="40" x="10" y="10"/>
                </svg>

                <svg x="0" y="60" height="60" width="60" onPointerDown={handleClickMoins} onPointerUp={handleClickMoins} style={{"cursor" : "pointer"}}>
                    <rect x="0" y="0" fill={colormoins} width="100%" height="100%"/>
                    <ReactMoins height="40" width="40" x="10" y="10"/>
                </svg>

                <line x1="0" y1="60"
                    x2="60" y2="60" 
                    stroke="black" 
                    strokeWidth="2"/>

            </svg>

            <rect x="545" y="-20" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="545" y="-20" fill="black" width="60" height="10"/>



            <rect x="545" y="40" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="545" y="50" fill="black" width="60" height="10"/>

            <svg id="menuDroite3" x="545" y="60" width="60" height="120">
                <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%"/>
                
                <svg x="0" y="0" height="60" width="60" onPointerDown={handleClickHelp} style={{"cursor" : "pointer"}}>
                    <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%"/>
                    <ReactHelp height="40" width="40" x="10" y="10"/>
                </svg>

                <svg x="0" y="60" height="60" width="60" onPointerDown={handleClickInfo} style={{"cursor" : "pointer"}}>
                    <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%"/>
                    <ReactInfo height="40" width="40" x="10" y="10"/>
                </svg>

                <line x1="0" y1="60"
                    x2="60" y2="60" 
                    stroke="black" 
                    strokeWidth="2"/>

            </svg>

            <rect x="545" y="176" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="545" y="176" fill="black" width="60" height="10"/>

            <rect x="545" y="236" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="545" y="246" fill="black" width="60" height="10"/>

            <svg id="menuDroite4" x="545" y="256" width="60" height="60">
                <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%"/>
                
                <svg x="0" y="0" height="60" width="60" onPointerDown={handleSave} onPointerUp={handleSave} style={{"cursor" : "pointer"}}>
                    <rect x="0" y="0" fill={colorButon} width="100%" height="100%"/>
                    <ReactSave height="50" width="50" x="7" y="8"/>
                </svg>

            </svg>

            <rect x="545" y="316" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="545" y="316" fill="black" width="60" height="10"/>


        </Fragment>
    )
}

export default MenuDroite;