import React, { Fragment, Component } from 'react';

class MenuGauche extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            y : 0
         }
         this.handleClickHaut = this.handleClickHaut.bind(this);
         this.handleClickBas = this.handleClickBas.bind(this);
         this.handleButtonRelease = this.handleButtonRelease.bind(this);
    }

    componentDidMount(){
        this.setState({
            y : parseInt(localStorage.getItem('y')) || 0,
        })
        this.props.changerY(this.state.y);
    }

    componentDidUpdate(prevProps,prevState){
        if( prevState.y !== this.state.y ){
            localStorage.setItem('y', this.state.y );
        }
    }

    handleClickHaut(e){
        e.preventDefault();
        
        this.buttonPressTimer = setInterval(() => {
            if(this.state.y < 0){
                this.setState({
                    y : this.state.y+20,
                });
                this.props.changerY(this.state.y+20);
            }
        }, 120);
    }

    handleButtonRelease(){
        clearInterval(this.buttonPressTimer);
        this.buttonPressTimer = false;
    }

    handleClickBas(e){
        e.preventDefault();
        const h = 680 - this.props.atomes.length*60;
    
        this.buttonPressTimer = setInterval(() => {
            if(this.state.y > h){
                this.setState({
                    y : this.state.y-20,
                });
                this.props.changerY(this.state.y-20);
            }
        }, 120);
    }

    render() { 
        return ( 
            <Fragment>
            
            <rect x="-605" y="-360" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="-605" y="-350" fill="black" width="60" height="10"/>

            <path key="menuGaucheHaut" style={{"cursor" : "pointer"}} d="M-604,-344 L-575,-357 L-546,-344" fill="#7b7b7b" onPointerOver={this.handleClickHaut} onPointerOut={this.handleButtonRelease} />

            <svg key="menuGauche" id="menuGauche" x="-605" y="-340" width="60" height="680">
                
                <rect key="bg_menuGauche" x="0" y="0" fill="#7b7b7b" width="100%" height="100%"/>

                {
                    this.props.atomes.map( (e,index) => {
                        return (
                          <Fragment key={e.id+"fragment"}>
                              <svg key={e.id} x="0" y={e._y-15+this.state.y} where="menuGauche" info={JSON.stringify(e)} height="60" width="60" style={{"cursor" : this.props.liaison === false ? "pointer" : "default"}}>
                                  
                                  {
                                     (e.symbole.includes("plus") === true || e.symbole.includes("moins") === true) ? (
                                        <Fragment>
                                            <text textAnchor="middle" x="30" y="33" dominantBaseline="middle" fill="black" fontFamily="arial" fontSize="30" >{e.symbole.replace("plus","").replace("2moins","").replace("moins","")}</text>
                                            <text textAnchor="middle" x={  e.symbole.includes("2moins") === true ? "52" : "50"} y={ e.symbole.includes("plus") === true ? "15" : "10"} dominantBaseline="middle" fill="black" fontFamily="arial" fontSize={ e.symbole.includes("2moins") === true ? "15" : (e.symbole.includes("plus") === true || e.symbole.includes("moins") === true ) ? "18" : "21"} >{e.symbole.includes("plus") === true ? "+" : e.symbole.includes("2moins") === true ? "2-" : e.symbole.includes("moins") === true && "-"}</text>
                                        </Fragment>
                                     ) : (
                                        <text textAnchor="middle" x="30" y="33" dominantBaseline="middle" fill="black" fontFamily="arial" fontSize="30" >{e.symbole}</text>
                                     )
                                  }

                                  { 
                                      e.ids.map( idxy => {
                                        return (
                                            <circle key={idxy[0]} cx={`${idxy[2]/2}`} cy={`${idxy[3]/2}`} fill="black" r="3"/>
                                        );
                                      })
                                  }
                              
                              </svg>

                              {
                                index !== 0 && (
                                  <line key={e.id+"line"} x1="0" y1={60*index+this.state.y} 
                                      x2="60" y2={60*index+this.state.y}  
                                      stroke="black" 
                                      strokeWidth="2"/>
                                )
                              }
                              
                          </Fragment>
                        )
                    })
                }

            </svg>

            <rect x="-605" y="340" rx="10" ry="10" fill="black" width="60" height="20"/>
            <rect x="-605" y="340" fill="black" width="60" height="10"/>

            <path key="menuGaucheBas" style={{"cursor" : "pointer"}} d="M-604,343 L-575,357 L-546,343" fill="#7b7b7b" onPointerOver={this.handleClickBas} onPointerOut={this.handleButtonRelease} />

        </Fragment>
         );
    }
}
 
export default MenuGauche;

