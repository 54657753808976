import React, { useState } from 'react';

const Valider = (props) => {

    const [colorButon, setcolorButon] = useState("green")

    const  handleValidate = (e) => {
        e.preventDefault();
        if(colorButon === "green"){
            props.Validate();
        }
        setcolorButon(colorButon === "green" ? "#00b300" : "green");
    }

    if(props.nombremolecule > 1){
        return (
            <svg x={-225} y={304} width="400" height="40" style={{ cursor: "pointer"}} onPointerDown={handleValidate} onPointerUp={handleValidate}>
                <rect x="0" y="0" rx="20" ry="20" fill={colorButon} width="100%" height="100%"/>
                <text x="200" y="22" dominantBaseline="middle" fontWeight="bold" fontFamily="Arial" textAnchor="middle" fontSize="25" color="black">Verifier les structures de Lewis</text>
            </svg>
        );
    }else{
        return (
            <svg x={-185} y={304} width="370" height="40" style={{ cursor: "pointer"}} onPointerDown={handleValidate} onPointerUp={handleValidate}>
                <rect x="0" y="0" rx="20" ry="20" fill={colorButon} width="100%" height="100%"/>
                <text x="185" y="22" dominantBaseline="middle" fontWeight="bold" fontFamily="Arial" textAnchor="middle" fontSize="25" color="black">Verifier la structure de Lewis</text>
            </svg>
        );
    }

}

export default Valider;