import React, { Fragment } from 'react';

const Error = (props) => {

    const handleClick = (e) => {
        e.preventDefault();
        props.showError();
    }

    return ( 
        <Fragment>
        {
            ( props.error1 === true || props.error2 === true ) && (
                <svg width="620" height="120" x="-310" y="-125">
                    <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%" rx="4" ry="4"  stroke="black" strokeWidth="16"/>
                    <rect x="0" y="0" fill="#000000" width="100%" height="40" rx="4" ry="4"/>
                    <rect x="0" y="20" fill="#000000" width="100%" height="20"/>
                    <svg x="584" y="6" width="29" height="29" onPointerDown={handleClick} style={{"cursor" : "pointer"}}>
                        <rect x="0" y="0" rx="4" ry="4" width="29" height="29" fill="black" strokeWidth="8" stroke="#990000" />
                        <line x1="8" y1="8"
                          x2="21" y2="21" 
                          stroke="#990000" 
                          strokeWidth="4"/>
                        <line x1="21" y1="8"
                            x2="8" y2="21" 
                            stroke="#990000" 
                            strokeWidth="4"/>
                    </svg>
                    
                    
                    <text x="310" y="22" textAnchor="middle" dominantBaseline="middle" fill="#990000" fontFamily="arial" fontSize="22" fontWeight="bold" >Erreur</text>
                    <text x="310" y="80" textAnchor="middle" dominantBaseline="middle" fill="#990000" fontFamily="arial" fontSize="24" fontWeight="bold" >{ props.error1 === true ? "Vous ne pouvez pas relier plusieurs fois un électron" : "Vous ne pouvez pas relier ces deux électrons" }</text>
                    
                </svg>
            )
        }
        </Fragment>
     );
}
 
export default Error;