import React, { Fragment } from 'react';

const Juste = (props) => {

    const handleClick = (e) => {
        e.preventDefault();
        props.showJuste();
    }

    return ( 
        <Fragment>
        {
            props.afficher === true && (
                <svg width="480" height="120" x="-240" y="-125">
                    <rect x="0" y="0" fill="#7b7b7b" width="100%" height="100%" rx="4" ry="4" stroke="black" strokeWidth="16"/>
                    <rect x="0" y="0" fill="#000000" width="100%" height="40" rx="4" ry="4"/>
                    <rect x="0" y="20" fill="#000000" width="100%" height="20"/>
                    <svg x="444" y="6" width="29" height="29" onPointerDown={handleClick} style={{"cursor" : "pointer"}}>
                        <rect x="0" y="0" rx="4" ry="4" width="29" height="29" fill="black" strokeWidth="8" stroke="#990000" />
                        <line x1="8" y1="8"
                          x2="21" y2="21" 
                          stroke="#990000" 
                          strokeWidth="4"/>
                        <line x1="21" y1="8"
                            x2="8" y2="21" 
                            stroke="#990000" 
                            strokeWidth="4"/>
                    </svg>
                    
                    
                    <text x="240" y="22" textAnchor="middle" dominantBaseline="middle" fill={ props.juste === true ? "green" : "#990000" } fontFamily="arial" fontSize="22" fontWeight="bold" >{ props.juste === true ? "Juste" : "Faux" }</text>
                    {
                        props.nombremolecule === 1 ? (
                            <text x="240" y="80" textAnchor="middle" dominantBaseline="middle" fill={ props.juste === true ? "#006700" : "#990000" } fontFamily="arial" fontSize="24" fontWeight="bold" >{ props.juste === false ? "La structure de Lewis est fausse !" : "La structure de Lewis est juste" }</text>
                        ) : (
                            <text x="240" y="80" textAnchor="middle" dominantBaseline="middle" fill={ props.juste === true ? "#006700" : "#990000" } fontFamily="arial" fontSize="24" fontWeight="bold" >{ props.juste === false ? "Les structures de Lewis sont fausses !" : "Les structures de Lewis sont justes" }</text>
                        )
                    }

                </svg>
            )
        }
        </Fragment>
     );
}
 
export default Juste;